import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgTime(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4551 22.6998C18.3645 22.6998 23.1551 17.9092 23.1551 11.9998C23.1551 6.09031 18.3645 1.29976 12.4551 1.29976C6.54563 1.29976 1.75508 6.09031 1.75508 11.9998C1.75508 17.9092 6.54563 22.6998 12.4551 22.6998ZM12.4551 23.9998C19.0825 23.9998 24.4551 18.6272 24.4551 11.9998C24.4551 5.37234 19.0825 -0.000244141 12.4551 -0.000244141C5.82766 -0.000244141 0.455078 5.37234 0.455078 11.9998C0.455078 18.6272 5.82766 23.9998 12.4551 23.9998Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1564 6.64951C12.1564 6.29053 11.8654 5.99951 11.5064 5.99951C11.1475 5.99951 10.8564 6.29053 10.8564 6.64951V13.5539C10.8564 13.7039 10.9073 13.8421 10.9927 13.9522C11.0305 14.019 11.0778 14.0798 11.1342 14.1315L15.3897 18.0282C15.6545 18.2706 16.0194 18.2179 16.2049 17.9106C16.3903 17.6032 16.326 17.1574 16.0612 16.915L12.1564 13.3395V6.64951Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1564 13.3395L16.0612 16.915C16.077 16.9294 16.092 16.9446 16.1063 16.9603C16.1506 17.0092 16.188 17.0642 16.2182 17.1233C16.3425 17.3663 16.3451 17.678 16.2049 17.9106C16.0194 18.2179 15.6545 18.2706 15.3897 18.0282L11.1342 14.1315C11.0778 14.0798 11.0305 14.019 10.9927 13.9522C10.9073 13.8421 10.8564 13.7039 10.8564 13.5539V6.64951C10.8564 6.29053 11.1475 5.99951 11.5064 5.99951C11.8654 5.99951 12.1564 6.29053 12.1564 6.64951V13.3395Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgTime;
