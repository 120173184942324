import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgStats(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_19665_403204)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.999935 0.266602C1.40494 0.266602 1.73327 0.594926 1.73327 0.999935V14.4444C1.73327 16.4817 1.73483 17.9358 1.88335 19.0405C2.02903 20.1241 2.3039 20.7609 2.77141 21.2285C2.7714 21.2285 2.77142 21.2285 2.77141 21.2285M2.77141 21.2285C3.23891 21.696 3.87575 21.9708 4.95932 22.1165C6.064 22.265 7.5182 22.2666 9.55549 22.2666H22.9999C23.4049 22.2666 23.7333 22.5949 23.7333 22.9999C23.7333 23.4049 23.4049 23.7333 22.9999 23.7333H9.49996C7.5305 23.7333 5.97763 23.7333 4.76389 23.5701C3.51768 23.4026 2.51976 23.051 1.73433 22.2656C0.948917 21.4801 0.597309 20.4822 0.429758 19.2359C0.266576 18.0222 0.266587 16.4694 0.266602 14.4999L0.266602 0.999935C0.266602 0.594926 0.594927 0.266602 0.999935 0.266602M8.32449 4.17795C6.98416 4.17795 6.06248 4.98901 5.29529 6.26659C5.08679 6.61381 4.63629 6.72625 4.28907 6.51775C3.94186 6.30925 3.82941 5.85875 4.03791 5.51153C4.91701 4.0476 6.22867 2.71128 8.32449 2.71128C9.36395 2.71128 10.2395 2.97626 10.97 3.46553C11.6929 3.94969 12.231 4.62463 12.6496 5.3813C13.4335 6.79833 13.8659 8.64001 14.2715 10.3675C14.2905 10.4486 14.3095 10.5294 14.3284 10.6099C14.7661 12.4697 15.193 14.183 15.951 15.4412C16.3214 16.0559 16.7549 16.5322 17.2811 16.8571C17.8026 17.1791 18.4563 17.378 19.3111 17.378C20.663 17.378 21.5795 16.5614 22.3773 15.2794C22.5913 14.9355 23.0435 14.8302 23.3874 15.0442C23.7313 15.2582 23.8365 15.7104 23.6226 16.0543C22.7232 17.4995 21.4198 18.8446 19.3111 18.8446C18.2146 18.8446 17.2883 18.5852 16.5107 18.1051C15.7378 17.6279 15.1528 16.9583 14.6948 16.1981C13.796 14.7062 13.326 12.7529 12.9008 10.946C12.8897 10.8992 12.8788 10.8525 12.8678 10.8059C12.4406 8.9898 12.0493 7.32607 11.3662 6.09127C11.0241 5.47294 10.6285 5.00204 10.1538 4.68412C9.68679 4.37131 9.10098 4.17795 8.32449 4.17795Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_19665_403204">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgStats;
