import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgAutoTheme(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.67039 5.54766C8.67994 5.54187 8.68937 5.53695 8.69935 5.53245L8.71277 5.52846C8.74207 5.52051 8.77226 5.51778 8.80218 5.52045C8.8367 5.52346 8.87064 5.53342 8.90134 5.54974C8.93205 5.56607 8.95903 5.58852 8.98102 5.61568C9.00257 5.64277 9.01894 5.67402 9.02829 5.70756L9.66563 7.65375L9.60439 7.67032C9.54198 7.68708 9.47797 7.70395 9.41702 7.72391C9.33427 7.75101 9.2558 7.78366 9.17544 7.81428L8.53809 5.86809C8.51629 5.80775 8.51786 5.74138 8.54227 5.68226C8.56661 5.62296 8.61265 5.57495 8.67039 5.54766Z"
        fill="currentColor"
      />
      <path
        d="M4.31652 9.28115C4.34365 9.24036 4.38217 9.20811 4.4269 9.18856C4.46226 9.17289 4.50065 9.16523 4.53906 9.16635C4.57746 9.16747 4.61539 9.17714 4.64978 9.19471L6.48083 10.1223C6.42748 10.2155 6.37764 10.3093 6.3311 10.4045C6.30215 10.4635 6.27424 10.5231 6.247 10.5836L4.41595 9.65597C4.372 9.63416 4.33549 9.60011 4.30989 9.55818C4.28467 9.51613 4.27171 9.46782 4.27283 9.41878C4.27395 9.36974 4.28895 9.32188 4.31652 9.28115Z"
        fill="currentColor"
      />
      <path
        d="M6.83869 14.1002C6.07832 11.7784 7.34267 9.27443 9.66435 8.51412C9.9063 8.43488 10.1502 8.37772 10.3939 8.34146C10.0982 9.29702 10.0106 10.3458 10.181 11.417C10.5306 13.6147 11.8965 15.3978 13.6791 16.2836C13.3073 16.5594 12.8853 16.781 12.4214 16.9329C10.0993 17.6934 7.59912 16.4223 6.83869 14.1002Z"
        fill="currentColor"
      />
      <path
        d="M3.8378 14.911C3.86254 14.8466 3.91143 14.7948 3.97426 14.7669L3.99187 14.7607L3.99975 14.7585C4.00807 14.7552 4.01693 14.7523 4.02561 14.7501L5.97678 14.1111C5.99167 14.1653 6.00637 14.2214 6.02212 14.277C6.03057 14.3066 6.03933 14.3358 6.04876 14.3646C6.06177 14.4043 6.07607 14.443 6.09068 14.4814L6.13737 14.6015L4.1862 15.2404C4.12305 15.2687 4.05169 15.2706 3.98759 15.246C3.92313 15.2215 3.87145 15.1724 3.84351 15.1095C3.8152 15.0468 3.81343 14.9752 3.8378 14.911Z"
        fill="currentColor"
      />
      <path
        d="M7.51423 19.1168L8.44186 17.2858C8.59168 17.3711 8.74049 17.4508 8.89807 17.5211L7.97561 19.3504C7.9445 19.4115 7.89054 19.4578 7.82552 19.4791C7.76014 19.5005 7.68924 19.4951 7.62801 19.4643C7.56708 19.4331 7.52078 19.3791 7.49943 19.3139C7.4889 19.2818 7.48472 19.2478 7.48711 19.2139C7.48993 19.18 7.49914 19.1469 7.51423 19.1168Z"
        fill="currentColor"
      />
      <path
        d="M13.0798 19.7377L12.4423 17.7913C12.5256 17.7683 12.6078 17.7484 12.691 17.7212C12.7722 17.6945 12.8537 17.6609 12.9329 17.6307L13.5703 19.577C13.5813 19.6094 13.586 19.6437 13.5837 19.6778L13.5815 19.7C13.5775 19.7262 13.5695 19.752 13.5573 19.776C13.5421 19.8068 13.5211 19.8341 13.495 19.8565C13.4806 19.8688 13.4653 19.8795 13.4489 19.8884C13.4354 19.8957 13.4214 19.9017 13.4066 19.9065C13.3741 19.9172 13.3399 19.9212 13.3055 19.9186C13.2714 19.9158 13.238 19.9063 13.2079 19.8904C13.1966 19.8847 13.1858 19.878 13.1757 19.8707C13.1581 19.8583 13.1422 19.8434 13.1282 19.8269C13.1065 19.8007 13.09 19.7703 13.0798 19.7377Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2386 8.51423C14.5854 7.29762 15.4329 6.31226 16.5434 5.77669C16.777 5.66404 16.7626 5.29952 16.5044 5.27455C13.9779 5.03012 11.5363 6.63125 10.831 9.17829C10.0531 11.9895 11.6984 14.9386 14.5238 15.7463C16.3896 16.2796 18.3976 15.7372 19.7517 14.4016C19.9363 14.2195 19.7197 13.9339 19.4689 13.9996C16.3574 14.8152 13.1572 12.308 14.2386 8.51423Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgAutoTheme;
