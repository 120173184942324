import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgInProgress(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 8 7" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14768_268730)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.0687 0.915086C2.84154 0.915086 1.84673 1.9099 1.84673 3.13706C1.84673 4.36422 2.84154 5.35904 4.0687 5.35904C5.29586 5.35904 6.29068 4.36422 6.29068 3.13706C6.29068 1.9099 5.29586 0.915086 4.0687 0.915086ZM1.0625 3.13706C1.0625 1.47678 2.40842 0.130859 4.0687 0.130859C5.72898 0.130859 7.0749 1.47678 7.0749 3.13706C7.0749 4.79734 5.72898 6.14326 4.0687 6.14326C2.40842 6.14326 1.0625 4.79734 1.0625 3.13706Z"
          fill="#C8E2FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.07471 3.09881C7.08137 3.62208 6.95131 4.13803 6.69741 4.59563C6.4435 5.05322 6.07454 5.43661 5.62701 5.70787C5.17949 5.97913 4.6689 6.12887 4.14576 6.14228C3.62261 6.15568 3.10503 6.03229 2.6442 5.78431C2.18337 5.53632 1.79526 5.17233 1.51825 4.72834C1.40362 4.54461 1.45963 4.30274 1.64336 4.18811C1.82709 4.07348 2.06896 4.1295 2.1836 4.31323C2.38834 4.64139 2.67521 4.91043 3.01582 5.09372C3.35644 5.27701 3.739 5.36822 4.12567 5.35831C4.51234 5.3484 4.88973 5.23772 5.22051 5.03722C5.55129 4.83673 5.824 4.55335 6.01167 4.21513C6.19934 3.87691 6.29547 3.49556 6.29054 3.10879C6.28562 2.72202 6.17982 2.34323 5.98361 2.0099C5.78739 1.67656 5.50756 1.40022 5.17178 1.2082C4.83601 1.01619 4.45593 0.915151 4.06913 0.915086C3.85257 0.915049 3.67704 0.739464 3.67708 0.522906C3.67712 0.306348 3.8527 0.130823 4.06926 0.130859C4.59258 0.130948 5.10681 0.267645 5.56109 0.52743C6.01537 0.787216 6.39397 1.16109 6.65944 1.61207C6.92491 2.06306 7.06805 2.57553 7.07471 3.09881Z"
          fill="#5BABFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_14768_268730">
          <rect
            width={6.27381}
            height={6.27381}
            fill="white"
            transform="translate(0.931763)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgInProgress;
