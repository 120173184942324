import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgNumber(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
      <g id="Number">
        <path
          id="Vector"
          d="M12.379 2.00335C12.315 2.01277 12.2532 2.03276 12.1962 2.06255L0.767986 7.73863C0.581526 7.83102 0.464088 8.01691 0.464088 8.21997C0.464088 8.42303 0.581526 8.60912 0.767986 8.70151L12.1962 14.3776C12.3549 14.4556 12.5427 14.4556 12.7013 14.3776L24.1296 8.70151C24.316 8.60912 24.4335 8.42303 24.4335 8.21997C24.4335 8.01692 24.316 7.83102 24.1296 7.73863L12.7013 2.06255C12.6024 2.01202 12.4902 1.99128 12.379 2.00335ZM12.4486 3.15213L22.6574 8.22006L12.4486 13.288L2.23983 8.22006L12.4486 3.15213ZM0.994475 11.7251C0.73627 11.7331 0.517924 11.9118 0.466014 12.1571C0.4141 12.4024 0.542817 12.6499 0.776911 12.7557L12.2051 18.1615C12.3591 18.2341 12.5388 18.2341 12.6928 18.1615L24.121 12.7557C24.2598 12.6965 24.3679 12.585 24.4202 12.447C24.4727 12.309 24.4648 12.1561 24.3983 12.024C24.3318 11.8916 24.2128 11.7913 24.0683 11.746C23.9241 11.7008 23.7668 11.7148 23.6332 11.7843L12.4488 17.0719L1.26451 11.7843C1.1809 11.7428 1.08819 11.7225 0.994475 11.7251ZM0.994475 15.5091C0.73627 15.517 0.517924 15.6957 0.466014 15.941C0.414104 16.1863 0.542817 16.4341 0.776911 16.5396L12.2051 21.9454C12.3591 22.0182 12.5388 22.0182 12.6928 21.9454L24.121 16.5396C24.2598 16.4806 24.3679 16.3692 24.4202 16.231C24.4727 16.093 24.4648 15.9403 24.3983 15.8079C24.3318 15.6756 24.2128 15.5753 24.0683 15.5302C23.9241 15.4849 23.7668 15.4987 23.6332 15.5683L12.4488 20.8558L1.26451 15.5683C1.1809 15.527 1.08819 15.5065 0.994475 15.5091Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth={0.15}
        />
      </g>
    </svg>
  );
}
export default SvgNumber;
