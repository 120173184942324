import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgCopy(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.4327 5.58132H16.0737V1.95344C16.071 1.43619 15.8811 0.941128 15.5451 0.575383C15.2091 0.209663 14.7541 0.00277478 14.2789 0H4.02234C3.54722 0.00280315 3.09223 0.209677 2.75619 0.575383C2.42017 0.941104 2.23026 1.43614 2.22754 1.95344V16.465C2.23029 16.9823 2.42019 17.4776 2.75619 17.8433C3.09221 18.209 3.54722 18.4157 4.02234 18.4187H8.38133V22.0466C8.38408 22.5638 8.57398 23.0589 8.90999 23.4246C9.246 23.7903 9.70101 23.9972 10.1761 24H20.4327C20.9079 23.9972 21.3628 23.7903 21.6989 23.4246C22.0349 23.0589 22.2248 22.5639 22.2275 22.0466V7.53495C22.2248 7.0177 22.0349 6.52245 21.6989 6.1567C21.3629 5.79098 20.9079 5.58429 20.4327 5.58132ZM4.02278 16.7441C3.88129 16.7441 3.76625 16.6192 3.76625 16.465V1.95344C3.76625 1.79927 3.88112 1.67443 4.0226 1.67443H14.2792C14.3472 1.67443 14.4125 1.70377 14.4605 1.7561C14.5086 1.80842 14.5356 1.87944 14.5356 1.95344V5.58132H10.1766C9.70145 5.58431 9.24647 5.791 8.91043 6.1567C8.57441 6.52242 8.3845 7.01765 8.38177 7.53495V16.7443L4.02278 16.7441ZM20.689 22.0467C20.689 22.1208 20.662 22.1918 20.614 22.2441C20.5659 22.2964 20.5006 22.3258 20.4327 22.3258H10.176C10.0346 22.3258 9.91969 22.2009 9.91969 22.0467V7.53515C9.91969 7.38097 10.0346 7.25595 10.176 7.25595H20.4327C20.5006 7.25595 20.5659 7.28548 20.614 7.3378C20.6621 7.39013 20.689 7.46114 20.689 7.53515V22.0467Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgCopy;
