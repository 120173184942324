import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgKey(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg">
      <g id="key">
        <path
          id="Vector"
          d="M3.26028 12.4995C3.20753 12.4995 3.1495 12.4942 3.10203 12.489L1.95736 12.3307C1.40876 12.2569 0.912912 11.7663 0.828513 11.2072L0.670263 10.052C0.617513 9.68278 0.775763 9.20278 1.03951 8.93377L3.35523 6.61815C2.98071 5.12011 3.41325 3.53768 4.51573 2.44581C6.22482 0.742057 9.00473 0.736782 10.7191 2.44581C11.5473 3.27394 12.0009 4.37637 12.0009 5.54737C12.0009 6.71837 11.5473 7.82079 10.7191 8.64893C9.61136 9.74608 8.03414 10.1786 6.54659 9.79883L4.2256 12.1144C4.00405 12.3465 3.60843 12.4995 3.26028 12.4995ZM7.61214 1.95525C6.68902 1.95525 5.77117 2.30339 5.0696 3.00493C4.1201 3.94911 3.77723 5.3311 4.17285 6.61815C4.21505 6.76056 4.17813 6.90826 4.07263 7.01375L1.59338 9.49289C1.50371 9.58256 1.42986 9.81465 1.44569 9.93597L1.60393 11.0911C1.63558 11.2916 1.85186 11.5184 2.05231 11.5448L3.20225 11.703C3.32885 11.7241 3.56095 11.6503 3.65063 11.5606L6.14042 9.07619C6.24592 8.97069 6.39889 8.93904 6.53604 8.98124C7.80731 9.38212 9.19463 9.03926 10.1441 8.0898C10.8193 7.41463 11.1939 6.50737 11.1939 5.54737C11.1939 4.58209 10.8193 3.6801 10.1441 3.00493C9.45838 2.30866 8.53526 1.95525 7.61214 1.95525Z"
          fill="#727272"
        />
        <path
          id="Vector_2"
          d="M4.84974 11.3351C4.74951 11.3351 4.64929 11.2982 4.57016 11.2191L3.35692 10.0059C3.20394 9.85291 3.20394 9.59973 3.35692 9.44676C3.50989 9.29379 3.76309 9.29379 3.91607 9.44676L5.12931 10.66C5.28229 10.8129 5.28229 11.0661 5.12931 11.2191C5.05019 11.2982 4.94996 11.3351 4.84974 11.3351Z"
          fill="#727272"
        />
        <path
          id="Vector_3"
          d="M7.64781 6.69786C6.99371 6.69786 6.46094 6.16511 6.46094 5.51104C6.46094 4.85697 6.99371 4.32422 7.64781 4.32422C8.30191 4.32422 8.83468 4.85697 8.83468 5.51104C8.83468 6.16511 8.30191 6.69786 7.64781 6.69786ZM7.64781 5.11543C7.43153 5.11543 7.25218 5.29478 7.25218 5.51104C7.25218 5.72731 7.43153 5.90665 7.64781 5.90665C7.86408 5.90665 8.04343 5.72731 8.04343 5.51104C8.04343 5.29478 7.86408 5.11543 7.64781 5.11543Z"
          fill="#727272"
        />
      </g>
    </svg>
  );
}
export default SvgKey;
