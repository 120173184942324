import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgBorderRadius(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons">
        <path
          id="Border-Radius"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.48926 7.74652C3.48926 5.15304 5.592 3 8.24997 3C8.53793 3 8.77138 3.22074 8.77138 3.49304C8.77138 3.76533 8.53793 3.98607 8.24997 3.98607C6.22533 3.98607 4.53208 5.64173 4.53208 7.74652C4.53208 8.01881 4.29864 8.23955 4.01067 8.23955C3.7227 8.23955 3.48926 8.01881 3.48926 7.74652ZM16.2071 3.49304C16.2071 3.22074 16.4406 3 16.7286 3C19.3865 3 21.4893 5.15304 21.4893 7.74652C21.4893 8.01882 21.2558 8.23956 20.9678 8.23956C20.6799 8.23956 20.4464 8.01882 20.4464 7.74652C20.4464 5.64173 18.7532 3.98607 16.7286 3.98607C16.4406 3.98607 16.2071 3.76533 16.2071 3.49304ZM5.60885 7.74638C5.60885 6.32747 6.76259 5.1266 8.24991 5.1266L16.7285 5.1266C18.2158 5.1266 19.3696 6.32747 19.3696 7.74638L19.3696 16.2533C19.3696 17.6722 18.2158 18.8731 16.7285 18.8731L8.2499 18.8731C6.76259 18.8731 5.60885 17.6722 5.60885 16.2533L5.60885 7.74638ZM8.24991 6.11267C7.39592 6.11267 6.65167 6.81615 6.65167 7.74638L6.65167 16.2533C6.65167 17.1836 7.39592 17.887 8.2499 17.887L16.7285 17.887C17.5825 17.887 18.3267 17.1836 18.3267 16.2533L18.3267 7.74638C18.3267 6.81616 17.5825 6.11267 16.7285 6.11267L8.24991 6.11267ZM4.01067 15.7604C4.29864 15.7604 4.53208 15.9812 4.53208 16.2535C4.53208 18.3583 6.22533 20.0139 8.24996 20.0139C8.53793 20.0139 8.77138 20.2347 8.77138 20.507C8.77138 20.7793 8.53793 21 8.24996 21C5.592 21 3.48926 18.847 3.48926 16.2535C3.48926 15.9812 3.7227 15.7604 4.01067 15.7604ZM20.9678 15.7604C21.2558 15.7604 21.4893 15.9812 21.4893 16.2535C21.4893 18.847 19.3865 21 16.7285 21C16.4406 21 16.2071 20.7793 16.2071 20.507C16.2071 20.2347 16.4406 20.0139 16.7285 20.0139C18.7532 20.0139 20.4464 18.3583 20.4464 16.2535C20.4464 15.9812 20.6799 15.7604 20.9678 15.7604Z"
          fill="#727272"
        />
      </g>
    </svg>
  );
}
export default SvgBorderRadius;
