import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgNoNotification(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 1000007661">
        <g id="Group 1000007530">
          <path
            id="Subtract"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.08063 7.73123C5.08063 4.14247 7.99067 1.2332 11.5804 1.2332C14.1703 1.2332 16.4065 2.7476 17.4511 4.93898L18.3676 4.02277C17.0543 1.62551 14.5072 0 11.5804 0C7.30941 0 3.8471 3.46139 3.8471 7.73123C3.8471 9.21129 3.06751 10.9332 2.1141 12.2068C1.41485 13.1409 1.00049 14.3016 1.00049 15.5573C1.00049 17.1616 1.67567 18.6083 2.75746 19.6287L3.63017 18.7563C2.7714 17.9594 2.23402 16.8211 2.23402 15.5573C2.23402 14.577 2.55661 13.6739 3.1017 12.9457C4.14201 11.5559 5.08063 9.57778 5.08063 7.73123ZM6.16446 19.8996L5.10978 20.954C5.58391 21.0845 6.08322 21.1542 6.59882 21.1542H8.17177C8.46269 22.7722 9.87812 24 11.5804 24C13.2827 24 14.6981 22.7722 14.989 21.1542H16.562C19.6538 21.1542 22.1603 18.6484 22.1603 15.5573C22.1603 14.3016 21.7459 13.1409 21.0467 12.2068C20.0933 10.9332 19.3137 9.21129 19.3137 7.73123C19.3137 7.41895 19.2952 7.111 19.2592 6.8084L18.0858 7.98147C18.1649 9.75478 19.0656 11.6185 20.0591 12.9457C20.6042 13.6739 20.9268 14.577 20.9268 15.5573C20.9268 17.9673 18.9726 19.9209 16.562 19.9209H6.59882C6.45223 19.9209 6.30733 19.9137 6.16446 19.8996ZM11.5804 22.7668C10.5628 22.7668 9.70449 22.0854 9.43694 21.1542H13.7238C13.4563 22.0854 12.5979 22.7668 11.5804 22.7668Z"
            fill="currentColor"
          />
          <path
            id="Rectangle 6608"
            d="M19.9662 3.42426C20.2006 3.18995 20.5806 3.18995 20.815 3.42426C21.0494 3.65858 21.0494 4.03848 20.815 4.27279L2.27315 22.8096C2.03877 23.0439 1.65877 23.0439 1.42439 22.8096C1.19001 22.5753 1.19001 22.1954 1.42439 21.9611L19.9662 3.42426Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
export default SvgNoNotification;
