import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgEvmCategory(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 29 28" xmlns="http://www.w3.org/2000/svg">
      <circle cx={14.25} cy={8} r={7} fill="#469BF5" />
      <g clipPath="url(#clip0_9706_64247)">
        <path
          d="M14.2491 3L14.182 3.22789V9.84004L14.2491 9.90697L17.3183 8.09273L14.2491 3Z"
          fill="#85BBF5"
        />
        <path
          d="M14.2491 3L11.1798 8.09273L14.2491 9.90697V6.69762V3Z"
          fill="#E2EBF5"
        />
        <path
          d="M14.2491 10.4891L14.2113 10.5352V12.8905L14.2491 13.0009L17.3202 8.67578L14.2491 10.4891Z"
          fill="#85BBF5"
        />
        <path
          d="M14.2491 13.0009V10.4891L11.1798 8.67578L14.2491 13.0009Z"
          fill="#E2EBF5"
        />
        <path
          d="M14.2491 9.90858L17.3184 8.09433L14.2491 6.69922V9.90858Z"
          fill="#69ADF5"
        />
        <path
          d="M11.1798 8.09433L14.2491 9.90858V6.69922L11.1798 8.09433Z"
          fill="#85BBF5"
        />
      </g>
      <circle cx={7.25} cy={20} r={7} fill="#469BF5" />
      <g clipPath="url(#clip1_9706_64247)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.3298 22.9628H5.50681C5.78393 22.9628 5.92249 22.9628 6.04615 22.9285C6.18173 22.8882 6.30539 22.8168 6.4082 22.7184C6.50261 22.6285 6.57073 22.5092 6.70512 22.2741L6.7052 22.2739L6.70915 22.267L8.2357 19.5685C8.37128 19.3316 8.43833 19.2124 8.46813 19.0873C8.5009 18.9517 8.5009 18.8087 8.46813 18.6731C8.43855 18.5489 8.37082 18.4305 8.23726 18.1972L8.23422 18.1918L7.63676 17.1489C7.51311 16.9314 7.45053 16.8226 7.37157 16.7824C7.28664 16.7392 7.18532 16.7392 7.1004 16.7824C7.02144 16.8226 6.95887 16.9314 6.8352 17.1489L3.92902 22.2715C3.80685 22.486 3.74576 22.5933 3.75023 22.6812C3.75619 22.7766 3.80535 22.863 3.88581 22.9151C3.9603 22.9628 4.08247 22.9628 4.3298 22.9628ZM10.1657 22.9628H8.47915C8.23034 22.9628 8.10519 22.9628 8.03218 22.9151C7.95173 22.863 7.90257 22.7751 7.89661 22.6797C7.89216 22.5925 7.95374 22.4862 8.07694 22.2736L8.07697 22.2735L8.07986 22.2685L8.92165 20.8233C9.04531 20.6103 9.10788 20.5045 9.18536 20.4643C9.27024 20.4211 9.37014 20.4211 9.45501 20.4643C9.53194 20.5034 9.59184 20.6047 9.71043 20.8053L9.71061 20.8056L9.72022 20.8218L10.565 22.267L10.5772 22.288L10.5772 22.2881C10.6943 22.4901 10.7541 22.5932 10.7498 22.6783C10.7453 22.7736 10.6946 22.8615 10.6142 22.9137C10.5397 22.9628 10.4145 22.9628 10.1657 22.9628H10.1657Z"
          fill="white"
        />
      </g>
      <circle cx={21.25} cy={20} r={7} fill="#469BF5" />
      <path
        d="M22.18 23.3046V24.4253L21.2029 25L20.2547 24.4253V23.3046L21.2029 23.8793L22.18 23.3046ZM16.9501 19.4253L17.8983 20V21.9253L19.5363 22.9023V24.023L16.9501 22.5V19.4253ZM25.4558 19.4253V22.5L22.8409 24.023V22.9023L24.4788 21.9253V20L25.4558 19.4253ZM22.8409 17.9023L23.8179 18.477V19.5977L22.18 20.5747V22.5287L21.2317 23.1034L20.2834 22.5287V20.5747L18.588 19.5977V18.477L19.565 17.9023L21.2029 18.8793L22.8409 17.9023ZM18.588 20.4023L19.5363 20.977V22.0977L18.588 21.523V20.4023ZM23.8179 20.4023V21.523L22.8696 22.0977V20.977L23.8179 20.4023ZM17.8983 16.9253L18.8754 17.5L17.8983 18.0747V19.1954L16.9501 18.6207V17.5L17.8983 16.9253ZM24.5075 16.9253L25.4846 17.5V18.6207L24.5075 19.1954V18.0747L23.5593 17.5L24.5075 16.9253ZM21.2029 16.9253L22.18 17.5L21.2029 18.0747L20.2547 17.5L21.2029 16.9253ZM21.2029 15L23.8179 16.523L22.8696 17.0977L21.2317 16.1207L19.565 17.0977L18.6167 16.523L21.2029 15Z"
        fill="white"
      />
      <defs>
        <clipPath id="clip0_9706_64247">
          <rect
            width={10}
            height={10}
            fill="white"
            transform="translate(9.25 3)"
          />
        </clipPath>
        <clipPath id="clip1_9706_64247">
          <rect
            width={8}
            height={8}
            fill="white"
            transform="translate(3.25 16)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgEvmCategory;
