import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgNotifications(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4545 1.2332C8.86576 1.2332 5.95649 4.14247 5.95649 7.73123C5.95649 9.57778 5.01813 11.5559 3.97809 12.9457C3.43315 13.6739 3.11064 14.577 3.11064 15.5573C3.11064 17.9673 5.06431 19.9209 7.47428 19.9209H17.4348C19.8447 19.9209 21.7984 17.9673 21.7984 15.5573C21.7984 14.577 21.4759 13.6739 20.931 12.9457C19.8909 11.556 18.9525 9.57778 18.9525 7.73123C18.9525 4.14247 16.0433 1.2332 12.4545 1.2332ZM15.8622 21.1542H17.4348C20.5258 21.1542 23.0316 18.6484 23.0316 15.5573C23.0316 14.3016 22.6173 13.1409 21.9183 12.2068C20.9651 10.9332 20.1858 9.21129 20.1858 7.73123C20.1858 3.46139 16.7244 0 12.4545 0C8.18468 0 4.72329 3.46139 4.72329 7.73123C4.72329 9.21129 3.94391 10.9332 2.99076 12.2068C2.2917 13.1409 1.87744 14.3016 1.87744 15.5573C1.87744 18.6484 4.38323 21.1542 7.47428 21.1542H9.04681C9.33766 22.7722 10.7527 24 12.4545 24C14.1563 24 15.5714 22.7722 15.8622 21.1542ZM14.5974 21.1542H10.3116C10.5791 22.0854 11.4372 22.7668 12.4545 22.7668C13.4718 22.7668 14.3299 22.0854 14.5974 21.1542Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgNotifications;
