export const navigationRoutes = {
  home: '/',
  fromSwap: 'from-swap',
  toSwap: 'to-swap',
  blockchains: 'blockchains',
  settings: 'settings',
  customTokens: 'custom-tokens',
  addCustomTokens: 'add-custom-tokens',
  liquiditySources: 'liquidity-sources',
  bridges: 'bridges',
  exchanges: 'exchanges',
  languages: 'languages',
  swaps: 'swaps',
  wallets: 'wallets',
  confirmSwap: 'confirm-swap',
  swapDetails: ':requestId',
  routes: 'routes',
};
