import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgLanguage(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons">
        <g id="Language">
          <path
            d="M14.3359 18.2133C14.2438 18.2133 14.1433 18.1883 14.0596 18.1464C13.7498 17.9874 13.6242 17.6109 13.7749 17.3012L15.5666 13.728C15.6754 13.5188 15.8931 13.3849 16.1275 13.3849C16.3619 13.3849 16.5796 13.5188 16.6885 13.7364L18.4801 17.3096C18.6392 17.6192 18.5136 17.9958 18.1954 18.1547C17.8857 18.3137 17.5089 18.1883 17.3499 17.8703L16.1275 15.4184L14.9052 17.8703C14.788 18.0878 14.5619 18.2133 14.3359 18.2133Z"
            fill="currentColor"
          />
          <path
            d="M17.5928 17.5773H14.6458C14.3025 17.5773 14.0179 17.2928 14.0179 16.9497C14.0179 16.6066 14.3025 16.3221 14.6458 16.3221H17.5928C17.936 16.3221 18.2207 16.6066 18.2207 16.9497C18.2207 17.2928 17.9444 17.5773 17.5928 17.5773Z"
            fill="currentColor"
          />
          <path
            d="M16.119 21C13.4316 21 11.2381 18.8159 11.2381 16.1213C11.2381 13.4268 13.4232 11.2427 16.119 11.2427C18.8065 11.2427 21 13.4268 21 16.1213C21 18.8159 18.8149 21 16.119 21ZM16.119 12.5063C14.1181 12.5063 12.4939 14.1297 12.4939 16.1297C12.4939 18.1297 14.1181 19.7532 16.119 19.7532C18.1116 19.7532 19.7442 18.1297 19.7442 16.1297C19.7442 14.1297 18.12 12.5063 16.119 12.5063Z"
            fill="currentColor"
          />
          <path
            d="M6.02235 12.5899C5.09304 12.5899 4.35629 12.3305 3.83722 11.8284C3.28465 11.2928 3 10.4811 3 9.43513V6.15482C3 4.12135 4.12187 3 6.15631 3H9.43819C10.4847 3 11.2884 3.27615 11.8326 3.83682C12.3601 4.38075 12.6196 5.16739 12.5945 6.17157V9.43513C12.6196 10.456 12.3601 11.2594 11.8159 11.8033C11.2717 12.3473 10.468 12.5983 9.42981 12.5816H6.16467C6.10607 12.5899 6.06421 12.5899 6.02235 12.5899ZM6.15631 4.2636C4.80839 4.2636 4.25582 4.81592 4.25582 6.16319V9.4435C4.25582 10.1381 4.40652 10.6401 4.70791 10.933C5.00094 11.2175 5.46979 11.3431 6.13119 11.3347H9.42981C10.1331 11.3515 10.6187 11.2092 10.9117 10.9163C11.2047 10.6234 11.3387 10.1297 11.3219 9.45188V6.15482C11.3387 5.48536 11.2047 5.00835 10.9201 4.71547C10.627 4.41421 10.1247 4.2636 9.42981 4.2636H6.15631Z"
            fill="currentColor"
          />
          <path
            d="M9.48837 7.48523H6.08928C5.74602 7.48523 5.46136 7.20071 5.46136 6.85761C5.46136 6.51452 5.74602 6.23 6.08928 6.23H9.48837C9.83163 6.23 10.1163 6.51452 10.1163 6.85761C10.1163 7.20071 9.84 7.48523 9.48837 7.48523Z"
            fill="currentColor"
          />
          <path
            d="M7.79723 7.48528C7.45397 7.48528 7.16932 7.20076 7.16932 6.85766V6.28863C7.16932 5.94554 7.45397 5.66102 7.79723 5.66102C8.14049 5.66102 8.42514 5.94554 8.42514 6.28863V6.85766C8.42514 7.20076 8.14049 7.48528 7.79723 7.48528Z"
            fill="currentColor"
          />
          <path
            d="M6.08928 10.1296C5.74602 10.1296 5.46136 9.84513 5.46136 9.50204C5.46136 9.15894 5.74602 8.87442 6.08928 8.87442C7.15254 8.87442 8.01487 7.97067 8.01487 6.84934C8.01487 6.50624 8.29953 6.22173 8.64278 6.22173C8.98604 6.22173 9.2707 6.50624 9.2707 6.84934C9.2707 8.65686 7.84743 10.1296 6.08928 10.1296Z"
            fill="currentColor"
          />
          <path
            d="M9.49674 10.1296C8.71813 10.1296 7.97301 9.73635 7.44556 9.04179C7.23626 8.76564 7.29487 8.3723 7.57115 8.16309C7.84743 7.95389 8.24091 8.01249 8.45022 8.28864C8.73487 8.67357 9.11162 8.8828 9.49674 8.8828C9.83999 8.8828 10.1246 9.16731 10.1246 9.51041C10.1246 9.8535 9.83999 10.1296 9.49674 10.1296Z"
            fill="currentColor"
          />
          <path
            d="M9.48842 20.9999C5.91351 20.9999 3 18.0878 3 14.5146C3 14.1715 3.28465 13.887 3.62791 13.887C3.97117 13.887 4.25582 14.1715 4.25582 14.5146C4.25582 16.9916 5.98049 19.0669 8.29957 19.6109L8.07352 19.2343C7.89771 18.933 7.98981 18.5481 8.29121 18.3723C8.58423 18.1966 8.97772 18.2887 9.15354 18.5899L10.0326 20.0543C10.1498 20.2468 10.1498 20.4895 10.041 20.682C9.92377 20.8744 9.71447 20.9999 9.48842 20.9999Z"
            fill="currentColor"
          />
          <path
            d="M20.3722 10.1213C20.0289 10.1213 19.7443 9.83681 19.7443 9.49371C19.7443 7.01673 18.0196 4.9414 15.7005 4.39747L15.9266 4.77405C16.1024 5.0753 16.0103 5.46026 15.7089 5.63599C15.4158 5.81173 15.0224 5.71967 14.8465 5.41841L13.9675 3.95398C13.8503 3.76152 13.8503 3.51884 13.9591 3.32637C14.0679 3.12553 14.2772 3.00838 14.5033 3.00838C18.0782 3.00838 20.9917 5.9205 20.9917 9.49371C21.0001 9.83681 20.7154 10.1213 20.3722 10.1213Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
export default SvgLanguage;
