export function isLegacyTransactionInstance(transaction) {
    return transaction.version === undefined;
}
export function serializeTransaction(transaction) {
    return isLegacyTransactionInstance(transaction)
        ? transaction.serialize({
            verifySignatures: false,
            requireAllSignatures: false
        })
        : transaction.serialize();
}
export function serializeTransactionMessage(transaction) {
    return isLegacyTransactionInstance(transaction)
        ? transaction.serializeMessage()
        : transaction.message.serialize();
}
export function addSignature(transaction, publicKey, signature) {
    if (isLegacyTransactionInstance(transaction)) {
        transaction.addSignature(publicKey, Buffer.from(signature));
    }
    else {
        const signerPubkeys = transaction.message.staticAccountKeys.slice(0, transaction.message.header.numRequiredSignatures);
        const signerIndex = signerPubkeys.findIndex((pubkey) => pubkey.equals(publicKey));
        if (signerIndex >= 0) {
            transaction.signatures[signerIndex] = signature;
        }
    }
}
