import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgDocument(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        d="M13.4552 8.11728H13.2618V3.37085C13.2618 3.32158 13.2418 3.27449 13.2065 3.24031C13.2038 3.23758 11.8927 1.96816 11.8902 1.9658C11.8549 1.9338 11.8102 1.91852 11.7636 1.91452H11.3889L11.0773 0.150203C11.0598 0.0512962 10.9651 -0.0145205 10.8668 0.00275184L3.4613 1.30817C3.36258 1.32563 3.29658 1.41981 3.31385 1.51871L3.43476 2.20852L1.1199 3.05104C1.02736 3.08268 0.975905 3.19304 1.01118 3.28413L2.29151 6.80168C1.84407 6.75477 1.42789 7.08567 1.43153 7.54748V15.2542C1.43153 15.6655 1.76607 16 2.17733 16H13.4554C13.8667 16 14.2012 15.6655 14.2012 15.2542V8.86326C14.2012 8.452 13.8667 8.11746 13.4554 8.11746L13.4552 8.11728ZM6.41086 8.11728C6.1496 8.1391 5.91997 7.86602 5.74179 7.71329V2.27815H11.5816V3.41194C11.5816 3.5123 11.6631 3.59376 11.7635 3.59376H12.8982V8.11728H6.41086ZM12.6404 3.23013H11.9453V2.53505L12.6404 3.23013ZM10.7508 0.392379L11.0197 1.91452H5.56016C5.4598 1.91452 5.37834 1.99598 5.37834 2.09634V7.38748C5.17908 7.21912 4.88817 6.89331 4.622 6.84095C4.54964 6.42678 3.74857 1.88761 3.70329 1.63489L10.7508 0.392379ZM1.41517 3.33049L3.50057 2.5716L4.24601 6.8015H2.67841L1.41517 3.33049ZM13.8374 15.254C13.8374 15.4647 13.666 15.6362 13.4552 15.6362H2.17715C1.96643 15.6362 1.79498 15.4647 1.79498 15.254V7.5473C1.79498 7.33657 1.96643 7.16512 2.17715 7.16512C2.38442 7.17058 4.11256 7.16112 4.36728 7.16512C4.50782 7.16512 4.64291 7.21676 4.74763 7.31057L5.78815 8.24255C5.9596 8.39618 6.18087 8.48091 6.41105 8.48091C6.41177 8.47836 13.0792 8.48182 13.0802 8.48164C13.0831 8.47964 13.452 8.48164 13.4554 8.48091C13.6661 8.48091 13.8376 8.65236 13.8376 8.86308L13.8374 15.254Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M7.81629 10.5452H3.30512C3.20476 10.5452 3.12331 10.6267 3.12331 10.727V13.1862C3.12331 13.2866 3.20476 13.3681 3.30512 13.3681H7.81629C7.91665 13.3681 7.9981 13.2866 7.9981 13.1862V10.727C7.9981 10.6267 7.91665 10.5452 7.81629 10.5452ZM7.63447 13.0044H3.48694V10.9089H7.63447V13.0044Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M7.81629 13.7564H3.30512C3.06767 13.7601 3.06495 14.1159 3.30512 14.12H7.81629C8.05373 14.1164 8.05646 13.7606 7.81629 13.7564Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M6.71577 5.27262H11.9909C12.2282 5.26917 12.2313 4.91318 11.9909 4.909H6.71577C6.4785 4.91245 6.47541 5.26844 6.71577 5.27262Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M11.9909 5.81807H6.71577C6.4785 5.82152 6.47541 6.17751 6.71577 6.18169H11.9909C12.2282 6.17824 12.2313 5.82225 11.9909 5.81807Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M10.9 6.72713H6.71577C6.47814 6.73077 6.47577 7.08658 6.71577 7.09076H10.9C11.1377 7.08713 11.14 6.73132 10.9 6.72713Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M6.71577 4.18174H8.71826C8.95662 4.17792 8.95771 3.8223 8.71826 3.81811H6.71577C6.47741 3.82193 6.47632 4.17756 6.71577 4.18174Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgDocument;
