import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgStyle(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g id="Style">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.12348 3.15363C4.43468 1.84244 6.40414 1.33337 8.96989 1.33337H10.9699C11.3381 1.33337 11.6366 1.63185 11.6366 2.00004C11.6366 2.36823 11.3381 2.6667 10.9699 2.6667H8.96989C6.53564 2.6667 5.0051 3.15763 4.06629 4.09644C3.12749 5.03525 2.63656 6.56579 2.63656 9.00004V15C2.63656 17.4343 3.12749 18.9648 4.06629 19.9036C5.0051 20.8424 6.53564 21.3334 8.96989 21.3334H14.9699C17.4041 21.3334 18.9347 20.8424 19.8735 19.9036C20.8123 18.9648 21.3032 17.4343 21.3032 15V13C21.3032 12.6318 21.6017 12.3334 21.9699 12.3334C22.3381 12.3334 22.6366 12.6318 22.6366 13V15C22.6366 17.5658 22.1275 19.5352 20.8163 20.8464C19.5051 22.1576 17.5356 22.6667 14.9699 22.6667H8.96989C6.40414 22.6667 4.43468 22.1576 3.12348 20.8464C1.81229 19.5352 1.30322 17.5658 1.30322 15V9.00004C1.30322 6.43428 1.81229 4.46483 3.12348 3.15363Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.6533 2.70122C19.1946 3.28847 17.4352 4.33801 15.7767 5.56928C14.1164 6.80192 12.599 8.18569 11.6112 9.41666C11.611 9.41693 11.6108 9.41719 11.6106 9.41746L10.5735 10.7179C11.1923 10.8971 11.7777 11.2353 12.2798 11.7366C12.7724 12.2208 13.1029 12.7819 13.2804 13.3834L14.5634 12.3603C14.5631 12.3605 14.5636 12.3601 14.5634 12.3603C15.794 11.3727 17.1734 9.85753 18.4009 8.19869C19.6287 6.53961 20.6747 4.77756 21.2617 3.31231L21.2625 3.3104C21.342 3.11383 21.2901 2.93258 21.1592 2.80166C21.0422 2.68463 20.858 2.62459 20.6533 2.70122ZM12.0795 14.126C12.0087 13.6037 11.7766 13.111 11.3436 12.686L11.3392 12.6817C10.8986 12.2411 10.3815 11.9985 9.85474 11.9241C9.70039 11.9146 9.58818 11.909 9.48218 11.9156C9.25816 11.9296 9.04212 11.83 8.90739 11.6504C8.77266 11.4709 8.73731 11.2356 8.81333 11.0244C8.92575 10.7122 9.09202 10.4104 9.3202 10.151L10.5694 8.5846L10.5706 8.58306C11.6528 7.23423 13.2651 5.77332 14.9819 4.49873C16.6987 3.22417 18.5618 2.1049 20.162 1.46169L20.1721 1.4576C20.9057 1.17667 21.6398 1.39669 22.102 1.85885C22.5709 2.32773 22.799 3.06605 22.4991 3.80916C21.856 5.4137 20.7423 7.27624 19.4727 8.99182C18.203 10.7076 16.7469 12.3178 15.3978 13.4002L15.3963 13.4015L13.8162 14.6615L13.8006 14.6736C13.5646 14.8505 13.3122 15.0042 13.0135 15.1211C12.8083 15.2014 12.5767 15.1751 12.3948 15.0509C12.2128 14.9267 12.1039 14.7206 12.1039 14.5003C12.1039 14.3817 12.0961 14.2604 12.0795 14.126Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.8644 11.9144C9.71005 11.9048 9.59784 11.8992 9.49184 11.9059C9.48226 11.9065 9.47267 11.9069 9.46307 11.9071C8.20229 11.9313 7.05337 12.8306 6.9031 14.2118C6.90307 14.2121 6.90312 14.2116 6.9031 14.2118L6.69317 16.1812L6.69292 16.1835C6.61951 16.8502 7.18196 17.4002 7.82596 17.328L7.82959 17.3276L9.79274 17.1183C10.4333 17.0359 10.9538 16.7633 11.337 16.3809C11.8429 15.866 12.1136 15.1948 12.1136 14.4905C12.1136 14.381 12.1057 14.2498 12.0891 14.1163C12.0188 13.5976 11.7885 13.1116 11.3489 12.6719C10.9082 12.2313 10.3912 11.9887 9.8644 11.9144ZM9.95752 10.5842C9.96234 10.5845 9.96712 10.5848 9.97184 10.5851C9.98719 10.5861 10.0025 10.5876 10.0177 10.5896C10.8435 10.6989 11.6383 11.0757 12.2917 11.7291C12.9311 12.3686 13.3008 13.1215 13.411 13.9418L13.4118 13.9478C13.435 14.1332 13.4469 14.3209 13.4469 14.4905C13.4469 15.5453 13.0383 16.5533 12.2854 17.3182L12.2817 17.3219C11.6862 17.9174 10.8895 18.3235 9.95398 18.4419L9.94094 18.4435L7.97251 18.6532C6.4778 18.8196 5.20177 17.5508 5.36746 16.0388C5.36741 16.0392 5.3675 16.0384 5.36746 16.0388L5.57735 14.0698C5.80677 11.9567 7.56956 10.6164 9.42348 10.5743C9.62029 10.5631 9.81008 10.575 9.95752 10.5842Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.3638 8.19047C11.3638 7.82228 11.6622 7.5238 12.0304 7.5238C14.4998 7.5238 16.4871 9.52342 16.4871 11.9805C16.4871 12.3487 16.1886 12.6471 15.8204 12.6471C15.4522 12.6471 15.1538 12.3487 15.1538 11.9805C15.1538 10.2575 13.7611 8.85713 12.0304 8.85713C11.6622 8.85713 11.3638 8.55866 11.3638 8.19047Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
export default SvgStyle;
