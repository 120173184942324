import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgRequest(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        d="M15.8844 12.8493H15.3478V4.32162C15.3478 3.66683 14.8162 3.13529 14.1615 3.13529H13.4681C13.3654 3.13529 13.2833 3.21746 13.2833 3.32017C13.2833 3.42289 13.3654 3.50505 13.4681 3.50505H14.1615C14.6134 3.50505 14.978 3.87225 14.978 4.32162V12.8493H1.09132V4.32162C1.09132 3.86968 1.45595 3.50505 1.90531 3.50505H2.82202C2.92473 3.50505 3.0069 3.42289 3.0069 3.32017C3.0069 3.21746 2.92473 3.13529 2.82202 3.13529H1.90531C1.25309 3.13529 0.721554 3.66683 0.721554 4.32162V12.8493H0.184882C0.0821698 12.8493 0 12.9315 0 13.0342V14.9806C0 15.5429 0.45707 16 1.01685 16H15.0473C15.6097 16 16.0668 15.5429 16.0668 14.9806V13.0342C16.0668 12.934 15.9846 12.8493 15.8844 12.8493ZM6.73536 13.2191H9.33398V13.7147C9.33398 13.8328 9.2364 13.9303 9.11828 13.9303H6.95105C6.83293 13.9303 6.73536 13.8328 6.73536 13.7147V13.2191ZM15.6996 14.9806C15.6996 15.3401 15.4068 15.6302 15.0499 15.6302H1.01942C0.659926 15.6302 0.369764 15.3375 0.369764 14.9806V13.2191H6.36559V13.7147C6.36559 14.0356 6.62751 14.3001 6.94848 14.3001H9.11571C9.43669 14.3001 9.6986 14.0382 9.6986 13.7147V13.2191H15.6944V14.9806H15.6996Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M4.10079 11.2419H11.966C12.3409 11.2419 12.6464 10.9363 12.6464 10.5614V0.680469C12.6464 0.305569 12.3409 0 11.966 0H6.08313C5.94447 0 5.81095 0.0308137 5.69026 0.0847376H5.68769C5.59011 0.125822 5.50281 0.184882 5.42321 0.259348L3.69764 1.97978C3.61547 2.06195 3.55128 2.15696 3.51019 2.25197C3.51019 2.25453 3.51019 2.25453 3.50762 2.2571C3.50762 2.25967 3.50762 2.26224 3.50506 2.26224C3.44856 2.38549 3.42032 2.51645 3.42032 2.65254V10.5614C3.42032 10.9389 3.72589 11.2419 4.10079 11.2419ZM11.966 0.367196C12.138 0.367196 12.2767 0.508426 12.2767 0.677901V10.5614C12.2767 10.7334 12.138 10.8721 11.966 10.8721H4.10079C3.92874 10.8721 3.79008 10.7334 3.79008 10.5614V2.65254C3.79008 2.60632 3.79779 2.5601 3.80806 2.51388H5.41037C5.70567 2.51388 5.94447 2.27508 5.94447 1.97978V0.385171C5.99069 0.3749 6.03434 0.367196 6.08313 0.367196H11.966ZM5.57728 0.629113V1.98235C5.57728 2.07479 5.50281 2.14925 5.41037 2.14925H4.05457L5.57728 0.629113Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M7.27716 3.18408H10.8105C10.9953 3.18408 11.1443 3.03515 11.1443 2.85026V1.65623C11.1443 1.47135 10.9953 1.32242 10.8105 1.32242H7.27716C7.09228 1.32242 6.94335 1.47135 6.94335 1.65623V2.85026C6.94335 3.03258 7.09485 3.18408 7.27716 3.18408ZM7.31311 1.69218H10.7771V2.81688H7.31311V1.69218Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M5.17413 3.94158C5.07142 3.94158 4.98925 4.02375 4.98925 4.12646C4.98925 4.22918 5.07142 4.31135 5.17413 4.31135H11.0647C11.1674 4.31135 11.2496 4.22918 11.2496 4.12646C11.2496 4.02375 11.1674 3.94158 11.0647 3.94158H5.17413Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M5.17413 5.4797H11.0647C11.1674 5.4797 11.2496 5.39753 11.2496 5.29482C11.2496 5.1921 11.1674 5.10993 11.0647 5.10993H5.17413C5.07142 5.10993 4.98925 5.1921 4.98925 5.29482C4.98925 5.39753 5.07399 5.4797 5.17413 5.4797Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M5.17413 6.65062H11.0647C11.1674 6.65062 11.2496 6.56845 11.2496 6.46574C11.2496 6.36302 11.1674 6.28085 11.0647 6.28085H5.17413C5.07142 6.28085 4.98925 6.36302 4.98925 6.46574C4.99182 6.56845 5.07399 6.65062 5.17413 6.65062Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M6.07543 7.25662C5.264 7.25662 4.60151 7.91655 4.60151 8.72797C4.60151 9.5394 5.26144 10.1993 6.07543 10.1993C6.88686 10.1993 7.54678 9.5394 7.54678 8.72797C7.54678 7.91655 6.88686 7.25662 6.07543 7.25662ZM6.07543 9.82956C5.46686 9.82956 4.97127 9.33397 4.97127 8.72797C4.97127 8.1194 5.46686 7.62638 6.07543 7.62638C6.684 7.62638 7.17702 8.12197 7.17702 8.72797C7.17702 9.33397 6.684 9.82956 6.07543 9.82956Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M9.99647 7.25662C9.18504 7.25662 8.52255 7.91655 8.52255 8.72797C8.52255 9.5394 9.18248 10.1993 9.99647 10.1993C10.8079 10.1993 11.4678 9.5394 11.4678 8.72797C11.4653 7.91655 10.8053 7.25662 9.99647 7.25662ZM9.99647 9.82956C9.3879 9.82956 8.89231 9.33397 8.89231 8.72797C8.89231 8.1194 9.3879 7.62638 9.99647 7.62638C10.605 7.62638 11.0981 8.12197 11.0981 8.72797C11.0981 9.33397 10.6025 9.82956 9.99647 9.82956Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M6.28599 8.41984L5.97785 8.61756L5.92393 8.51998C5.87514 8.43267 5.76216 8.39929 5.67228 8.44808C5.58241 8.49687 5.5516 8.60985 5.60039 8.69973L5.74932 8.96678C5.775 9.01043 5.81608 9.04381 5.86487 9.05665C5.88028 9.05922 5.89568 9.06179 5.90852 9.06179C5.94447 9.06179 5.97785 9.05152 6.00867 9.03354L6.48114 8.73054C6.56588 8.67662 6.59156 8.56107 6.53763 8.47633C6.48628 8.38902 6.37329 8.36591 6.28599 8.41984Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M10.415 8.30685C10.3431 8.23495 10.2276 8.23495 10.1557 8.30685L9.99647 8.46606L9.83727 8.30685C9.76537 8.23495 9.64982 8.23495 9.57792 8.30685C9.50602 8.37875 9.50602 8.4943 9.57792 8.5662L9.73712 8.72541L9.57792 8.88461C9.50602 8.95651 9.50602 9.07206 9.57792 9.14396C9.61387 9.17991 9.66009 9.19788 9.70888 9.19788C9.7551 9.19788 9.80388 9.17991 9.83983 9.14396L9.99904 8.98475L10.1582 9.14396C10.1942 9.17991 10.2404 9.19788 10.2892 9.19788C10.3354 9.19788 10.3842 9.17991 10.4202 9.14396C10.4921 9.07206 10.4921 8.95651 10.4202 8.88461L10.261 8.72541L10.4202 8.5662C10.4869 8.4943 10.4869 8.37875 10.415 8.30685Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgRequest;
