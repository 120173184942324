import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgLoading(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.432 11.1616C24.5297 11.9542 24.3082 12.7531 23.8169 13.3826C23.3254 14.012 22.6039 14.4206 21.8113 14.5181C21.0187 14.6157 20.2196 14.3945 19.5903 13.903C18.9607 13.4115 18.5523 12.69 18.4547 11.8974C18.3572 11.1047 18.5784 10.3059 19.0699 9.67634C19.5615 9.047 20.2827 8.63838 21.0753 8.54093C21.8682 8.44325 22.667 8.66448 23.2964 9.15599C23.9259 9.64753 24.3343 10.369 24.432 11.1616Z"
        fill="currentColor"
      />
      <path
        d="M19.9341 3.46405C20.4122 3.93997 20.6819 4.5865 20.6834 5.26117C20.6849 5.93583 20.4185 6.58367 19.9425 7.06182C19.4664 7.53997 18.8201 7.80962 18.1452 7.81117C17.4706 7.81273 16.8229 7.54622 16.3446 7.07027C15.8664 6.59435 15.5968 5.94782 15.5952 5.27315C15.5937 4.59832 15.8602 3.95065 16.3361 3.4725C16.812 2.99412 17.4586 2.72469 18.1332 2.72315C18.8081 2.72137 19.4557 2.98788 19.9341 3.46405H19.9341Z"
        fill="currentColor"
      />
      <path
        d="M13.2107 0.786521C13.7696 0.902717 14.2596 1.23635 14.5727 1.71383C14.8856 2.19109 14.9962 2.77341 14.88 3.33253C14.7638 3.89142 14.4301 4.38138 13.9527 4.69448C13.4752 5.00738 12.8931 5.118 12.334 5.0018C11.7751 4.8856 11.2851 4.55197 10.972 4.0745C10.6591 3.59701 10.5485 3.01491 10.6647 2.45579C10.7809 1.89691 11.1145 1.40694 11.592 1.09384C12.0693 0.780943 12.6516 0.670326 13.2107 0.786521Z"
        fill="currentColor"
      />
      <path
        d="M7.17135 1.74731C7.633 1.52184 8.16556 1.48928 8.65131 1.65655C9.13727 1.82359 9.53694 2.17707 9.76217 2.63895C9.98742 3.10059 10.0202 3.63293 9.85294 4.11891C9.68567 4.60487 9.33241 5.00431 8.87054 5.22977C8.40889 5.45502 7.87632 5.4878 7.39057 5.32053C6.90462 5.15327 6.50495 4.80001 6.27971 4.33814C6.05447 3.87626 6.02168 3.34392 6.18895 2.85794C6.35621 2.37221 6.70948 1.97254 7.17135 1.74731Z"
        fill="currentColor"
      />
      <path
        d="M2.63401 5.40181C2.85056 4.99971 3.21807 4.7002 3.65569 4.56905C4.09326 4.43792 4.56495 4.48609 4.96706 4.70264C5.36916 4.91942 5.66867 5.28693 5.79982 5.72449C5.93073 6.16206 5.88278 6.63375 5.666 7.03586C5.44945 7.43774 5.08194 7.73725 4.64432 7.86839C4.20676 7.99953 3.73506 7.95135 3.33295 7.7348C2.93085 7.51803 2.63134 7.15052 2.5002 6.71295C2.36906 6.27561 2.41723 5.80392 2.63401 5.40181Z"
        fill="currentColor"
      />
      <path
        d="M0.56274 10.2383C0.521481 9.8407 0.639906 9.44326 0.891915 9.13308C1.14393 8.82263 1.5088 8.62526 1.90623 8.58422C2.30387 8.54297 2.70153 8.66139 3.01172 8.9134C3.32191 9.16541 3.51931 9.53029 3.56057 9.92771C3.60161 10.3254 3.48318 10.7228 3.23117 11.033C2.97938 11.3432 2.61428 11.5408 2.21686 11.5818C1.81944 11.6231 1.42178 11.5047 1.1116 11.2527C0.801376 11.0006 0.604001 10.6358 0.56274 10.2383Z"
        fill="currentColor"
      />
      <path
        d="M0.838025 15.3494C0.582888 15.08 0.445065 14.7203 0.455102 14.3492C0.465138 13.9783 0.622145 13.6264 0.891549 13.3712C1.16095 13.1161 1.52071 12.9783 1.89182 12.9883C2.2627 12.9983 2.61439 13.1553 2.86976 13.4248C3.1249 13.6942 3.26272 14.0539 3.25269 14.4248C3.24265 14.7959 3.08564 15.1476 2.81624 15.403C2.54683 15.6581 2.18707 15.7959 1.8162 15.7859C1.44509 15.7759 1.0934 15.6188 0.838025 15.3494Z"
        fill="currentColor"
      />
      <path
        d="M3.04198 19.3038C2.70389 19.2485 2.40169 19.0611 2.20186 18.7828C2.00181 18.5047 1.92063 18.1586 1.97594 17.8205C2.03126 17.4825 2.21859 17.1802 2.4967 16.9802C2.7748 16.7803 3.12118 16.6989 3.45923 16.7542C3.7971 16.8096 4.09952 16.9971 4.29935 17.2752C4.4994 17.5533 4.58058 17.8995 4.52527 18.2375C4.46995 18.5756 4.28262 18.8778 4.00451 19.0779C3.72619 19.2777 3.38003 19.3591 3.04198 19.3038Z"
        fill="currentColor"
      />
      <path
        d="M6.31283 21.765C6.03451 21.9107 5.70999 21.9399 5.41025 21.8462C5.11051 21.7523 4.86029 21.5433 4.71463 21.2652C4.569 20.9869 4.53978 20.6624 4.63367 20.3626C4.72734 20.0629 4.93631 19.8127 5.21442 19.667C5.49275 19.5214 5.81744 19.4922 6.11701 19.5861C6.41674 19.6797 6.66697 19.8887 6.81263 20.167C6.95826 20.4451 6.98747 20.7699 6.89358 21.0696C6.79992 21.3691 6.59095 21.6194 6.31283 21.765Z"
        fill="currentColor"
      />
      <path
        d="M9.33649 22.6063C9.20825 22.8289 8.99683 22.9915 8.74883 23.0582C8.50061 23.1251 8.23633 23.0905 8.01353 22.9623C7.79096 22.8341 7.62838 22.6226 7.56169 22.3744C7.49478 22.1264 7.52935 21.8619 7.65759 21.6393C7.78583 21.4168 7.99725 21.2542 8.24548 21.1873C8.49348 21.1206 8.75798 21.1552 8.98055 21.2834C9.20334 21.4116 9.36593 21.623 9.43261 21.871C9.4993 22.1193 9.46473 22.3838 9.33649 22.6063Z"
        fill="currentColor"
      />
      <path
        d="M11.5464 22.9354C11.461 23.084 11.3201 23.1924 11.1546 23.2367C10.9891 23.2813 10.8129 23.2581 10.6644 23.1727C10.3553 22.9948 10.2491 22.5998 10.4271 22.2907C10.5127 22.1424 10.6537 22.034 10.819 21.9894C10.9844 21.945 11.1606 21.968 11.3091 22.0534C11.4575 22.139 11.5658 22.28 11.6104 22.4453C11.655 22.6107 11.6319 22.7871 11.5464 22.9354Z"
        fill="currentColor"
      />
      <path
        d="M13.7535 22.6851C13.6964 22.7839 13.6025 22.8562 13.4921 22.8859C13.3819 22.9158 13.2644 22.9004 13.1654 22.8433C12.9593 22.7246 12.8886 22.4615 13.0073 22.2554C13.1259 22.0493 13.3891 21.9784 13.5951 22.097C13.6942 22.1541 13.7664 22.248 13.7961 22.3584C13.8257 22.4686 13.8103 22.5861 13.7535 22.6851Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgLoading;
