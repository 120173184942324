import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgRefresh(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.29926 18.2997C2.82191 15.1563 2.5569 9.7767 5.6942 6.30604C8.8315 2.83538 14.2165 2.55094 17.6939 5.69429C18.4094 6.34109 19.0142 7.07007 19.4955 7.88186C19.6905 8.2039 19.5837 8.61775 19.2617 8.8127C18.9396 9.00765 18.5258 8.90088 18.3308 8.57884C17.9196 7.89116 17.4024 7.2535 16.7871 6.69737C13.8648 4.05575 9.34494 4.28377 6.70333 7.20608C4.06171 10.1284 4.28973 14.6483 7.21204 17.2899C10.1343 19.9315 14.6542 19.7035 17.2959 16.7812C18.251 15.7246 18.8622 14.4301 19.0662 13.0348C19.1179 12.6684 19.4622 12.4086 19.8353 12.4663C20.2017 12.518 20.4616 12.8623 20.4038 13.2354C20.1624 14.9006 19.436 16.4435 18.2996 17.7007C15.1556 21.1653 9.77661 21.443 6.29926 18.2997Z"
        fill="currentColor"
      />
      <path
        d="M19.2696 9.6089C19.1207 9.49207 19.0218 9.31141 19.01 9.10738L18.807 5.14476C18.788 4.77467 19.0763 4.45089 19.4464 4.43183C19.8165 4.41278 20.1402 4.70108 20.1593 5.07117L20.3622 9.0338C20.3813 9.40389 20.093 9.72767 19.7229 9.74672C19.5584 9.76661 19.3973 9.70903 19.2696 9.6089Z"
        fill="currentColor"
      />
      <path
        d="M15.5108 9.47903C15.3476 9.35108 15.2457 9.14511 15.2505 8.9198C15.2639 8.55221 15.575 8.25778 15.9482 8.26404L19.707 8.3939C20.0802 8.40016 20.3691 8.71837 20.3628 9.09152C20.3565 9.46466 20.0383 9.75353 19.6652 9.74728L15.9007 9.62451C15.7529 9.62312 15.6243 9.56804 15.5108 9.47903Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgRefresh;
