var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import WalletAdapter from './base';
import Wallet from './WalletProvider';
import bs58 from 'bs58';
var WebAdapter = /** @class */ (function (_super) {
    __extends(WebAdapter, _super);
    // @ts-ignore
    function WebAdapter(iframe, network, provider) {
        var _this = _super.call(this) || this;
        _this._instance = null;
        // @ts-ignore
        _this.handleMessage = function (data) {
            // nothing to do here
        };
        _this._sendRequest = function (method, params) { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!((_a = this._instance) === null || _a === void 0 ? void 0 : _a.sendRequest)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._instance.sendRequest(method, params)];
                    case 1: return [2 /*return*/, _c.sent()];
                    case 2:
                        if (!((_b = this._instance) === null || _b === void 0 ? void 0 : _b._sendRequest)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this._instance._sendRequest(method, params)];
                    case 3: return [2 /*return*/, _c.sent()];
                    case 4: throw new Error('Unsupported version of `@project-serum/sol-wallet-adapter`');
                }
            });
        }); };
        _this._handleConnect = function () {
            _this.emit('connect');
        };
        _this._handleDisconnect = function () {
            window.clearInterval(_this._pollTimer);
            _this.emit('disconnect');
        };
        _this._network = network;
        _this._provider = provider;
        return _this;
    }
    Object.defineProperty(WebAdapter.prototype, "publicKey", {
        get: function () {
            return this._instance.publicKey || null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WebAdapter.prototype, "connected", {
        get: function () {
            return this._instance.connected || false;
        },
        enumerable: false,
        configurable: true
    });
    WebAdapter.prototype.connect = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._instance = new Wallet(this._provider, this._network);
                        this._instance.on('connect', this._handleConnect);
                        this._instance.on('disconnect', this._handleDisconnect);
                        this._pollTimer = window.setInterval(function () {
                            var _a, _b;
                            // @ts-ignore
                            if (((_b = (_a = _this._instance) === null || _a === void 0 ? void 0 : _a._popup) === null || _b === void 0 ? void 0 : _b.closed) !== false) {
                                _this._handleDisconnect();
                            }
                        }, 200);
                        return [4 /*yield*/, this._instance.connect()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    WebAdapter.prototype.disconnect = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.connected) {
                            throw new Error('Wallet not connected');
                        }
                        this._instance.removeAllListeners('connect');
                        this._instance.removeAllListeners('disconnect');
                        return [4 /*yield*/, this._instance.disconnect()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    WebAdapter.prototype.signTransaction = function (transaction) {
        return __awaiter(this, void 0, void 0, function () {
            var signedTransaction;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.connected) {
                            throw new Error('Wallet not connected');
                        }
                        return [4 /*yield*/, this._sendRequest('signTransactionV2', {
                                transaction: bs58.encode(transaction)
                            })];
                    case 1:
                        signedTransaction = (_a.sent()).transaction;
                        return [2 /*return*/, bs58.decode(signedTransaction)];
                }
            });
        });
    };
    WebAdapter.prototype.signAllTransactions = function (transactions) {
        return __awaiter(this, void 0, void 0, function () {
            var signedTransactions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.connected) {
                            throw new Error('Wallet not connected');
                        }
                        return [4 /*yield*/, this._sendRequest('signAllTransactionsV2', {
                                transactions: transactions.map(function (transaction) { return bs58.encode(transaction); })
                            })];
                    case 1:
                        signedTransactions = (_a.sent()).transactions;
                        return [2 /*return*/, signedTransactions.map(function (transaction) { return bs58.decode(transaction); })];
                }
            });
        });
    };
    WebAdapter.prototype.signAndSendTransaction = function (transaction, options) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.connected) {
                            throw new Error('Wallet not connected');
                        }
                        return [4 /*yield*/, this._sendRequest('signAndSendTransaction', {
                                transaction: bs58.encode(transaction),
                                options: options
                            })];
                    case 1:
                        response = (_a.sent());
                        return [2 /*return*/, response.signature];
                }
            });
        });
    };
    WebAdapter.prototype.signMessage = function (data, display) {
        if (display === void 0) { display = 'hex'; }
        return __awaiter(this, void 0, void 0, function () {
            var signature;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.connected) {
                            throw new Error('Wallet not connected');
                        }
                        return [4 /*yield*/, this._instance.sign(data, display)];
                    case 1:
                        signature = (_a.sent()).signature;
                        return [2 /*return*/, Uint8Array.from(signature)];
                }
            });
        });
    };
    return WebAdapter;
}(WalletAdapter));
export default WebAdapter;
