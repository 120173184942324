import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgCustomColors(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3172 1.14659C18.8459 -0.382197 21.3246 -0.382197 22.8534 1.14659C24.3822 2.67538 24.3822 5.15404 22.8534 6.68283L21.9458 7.59042C21.4696 8.06664 21.4696 8.83873 21.9458 9.31494C22.9734 10.3425 22.9734 12.0084 21.9458 13.036C20.9183 14.0635 19.2523 14.0635 18.2248 13.036L10.964 5.77524C9.93643 4.7477 9.93643 3.08172 10.964 2.05419C11.9915 1.02665 13.6575 1.02665 14.685 2.05419C15.1612 2.5304 15.9333 2.5304 16.4096 2.05419L17.3172 1.14659ZM21.8551 2.14486C20.8777 1.16739 19.2929 1.16739 18.3154 2.14486L17.4078 3.05245C16.3803 4.07999 14.7143 4.07999 13.6868 3.05245C13.2105 2.57624 12.4384 2.57624 11.9622 3.05245C11.486 3.52866 11.486 4.30076 11.9622 4.77697L19.223 12.0377C19.6992 12.5139 20.4713 12.5139 20.9475 12.0377C21.4238 11.5615 21.4238 10.7894 20.9475 10.3132C19.92 9.28567 19.92 7.6197 20.9475 6.59216L21.8551 5.68456C22.8326 4.7071 22.8326 3.12232 21.8551 2.14486ZM11.4177 9.85952C11.1922 9.63393 10.8264 9.63393 10.6008 9.85951L3.49641 16.9639C2.33926 18.121 1.62441 19.6473 1.47625 21.277L1.41434 21.9581C1.38168 22.3173 1.68265 22.6183 2.04192 22.5857L2.72301 22.5237C4.35276 22.3756 5.87898 21.6607 7.03613 20.5036L14.1405 13.3992C14.3661 13.1736 14.3661 12.8079 14.1405 12.5823L11.4177 9.85952ZM9.60255 8.86125C10.3795 8.08433 11.6391 8.08434 12.416 8.86125L15.1388 11.584C15.9157 12.3609 15.9157 13.6206 15.1388 14.3975L8.0344 21.5019C6.64371 22.8925 4.80947 23.7517 2.85082 23.9297L2.16973 23.9916C0.932446 24.1041 -0.104108 23.0676 0.00837295 21.8303L0.0702906 21.1492C0.24835 19.1905 1.10746 17.3563 2.49815 15.9656L9.60255 8.86125Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgCustomColors;
