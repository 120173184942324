import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgWarning(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.7245 22.4863H1.73091C1.38524 22.4863 1.05369 22.3491 0.809081 22.1049C0.56447 21.8606 0.426788 21.5293 0.42627 21.1836C0.426608 20.9568 0.485203 20.7339 0.59644 20.5362L11.0923 2.15775C11.1771 2.00885 11.2905 1.87815 11.4259 1.77315C11.5614 1.66815 11.7162 1.5909 11.8816 1.54584C12.0469 1.50078 12.2195 1.48878 12.3895 1.51055C12.5595 1.53231 12.7235 1.5874 12.8722 1.67267C13.075 1.78726 13.2427 1.95497 13.3573 2.15775L23.8531 20.544C23.9681 20.7424 24.0287 20.9677 24.0288 21.197C24.0288 21.4263 23.9683 21.6516 23.8534 21.85C23.7385 22.0485 23.5733 22.2131 23.3743 22.3272C23.1754 22.4414 22.9499 22.501 22.7206 22.5L22.7245 22.4863ZM11.0394 17.7724V20.1196H13.4121V17.7724H11.0394ZM10.9201 8.14893L11.5754 16.5166H12.8644L13.5314 8.16067L10.9201 8.14893Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgWarning;
