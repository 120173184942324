import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgOtherCategory(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
      <circle cx={14} cy={8} r={7} fill="#469BF5" />
      <path
        d="M17.464 9.86197L16.3084 11.0827C16.2833 11.1092 16.2529 11.1303 16.2191 11.1448C16.1853 11.1592 16.1489 11.1667 16.1121 11.1667H10.6342C10.608 11.1667 10.5824 11.1591 10.5606 11.145C10.5387 11.1309 10.5215 11.1108 10.5111 11.0872C10.5007 11.0636 10.4975 11.0375 10.5019 11.0121C10.5064 10.9867 10.5182 10.9632 10.5361 10.9444L11.6925 9.72366C11.7176 9.69722 11.7478 9.67612 11.7815 9.66167C11.8152 9.64722 11.8515 9.63974 11.8882 9.63968H17.3658C17.392 9.63968 17.4175 9.6472 17.4394 9.66132C17.4613 9.67544 17.4784 9.69554 17.4889 9.71915C17.4993 9.74276 17.5025 9.76885 17.4981 9.79423C17.4936 9.8196 17.4818 9.84315 17.464 9.86197ZM16.3084 7.40382C16.2833 7.3773 16.2529 7.35616 16.2191 7.34171C16.1853 7.32727 16.1489 7.31982 16.1121 7.31984H10.6342C10.608 7.31984 10.5824 7.32736 10.5606 7.34148C10.5387 7.3556 10.5215 7.3757 10.5111 7.39931C10.5007 7.42292 10.4975 7.44902 10.5019 7.47439C10.5064 7.49976 10.5182 7.52331 10.5361 7.54214L11.6925 8.76284C11.7176 8.78929 11.7478 8.81038 11.7815 8.82483C11.8152 8.83927 11.8515 8.84676 11.8882 8.84682H17.3658C17.392 8.84682 17.4175 8.8393 17.4394 8.82518C17.4613 8.81106 17.4784 8.79096 17.4889 8.76735C17.4993 8.74374 17.5025 8.71764 17.4981 8.69227C17.4936 8.6669 17.4818 8.64335 17.464 8.62453L16.3084 7.40382ZM10.6342 6.52698H16.1121C16.1489 6.527 16.1853 6.51956 16.2191 6.50511C16.2529 6.49066 16.2833 6.46952 16.3084 6.443L17.464 5.22229C17.4818 5.20347 17.4936 5.17992 17.4981 5.15455C17.5025 5.12918 17.4993 5.10308 17.4889 5.07947C17.4784 5.05586 17.4613 5.03576 17.4394 5.02164C17.4175 5.00752 17.392 5 17.3658 5H11.8882C11.8515 5.00006 11.8152 5.00755 11.7815 5.02199C11.7478 5.03644 11.7176 5.05754 11.6925 5.08398L10.5364 6.30469C10.5186 6.32349 10.5067 6.34702 10.5023 6.37236C10.4978 6.3977 10.501 6.42378 10.5114 6.44738C10.5217 6.47098 10.5389 6.49108 10.5607 6.50522C10.5825 6.51936 10.608 6.52692 10.6342 6.52698Z"
        fill="white"
      />
      <circle cx={7} cy={20} r={7} fill="#469BF5" />
      <g clipPath="url(#clip0_9706_69204)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.76322 18.3239L3.94253 17.7704C3.97931 17.6573 4.06758 17.5699 4.18069 17.5359L4.73701 17.3649C4.81425 17.341 4.81425 17.2324 4.73793 17.2076L4.18345 17.0283C4.07126 16.9915 3.98299 16.9033 3.94896 16.7901L3.77793 16.2338C3.75402 16.1566 3.64551 16.1566 3.62069 16.2329L3.44138 16.7865C3.40459 16.8987 3.31632 16.9869 3.20322 17.021L2.64689 17.192C2.56965 17.2159 2.56873 17.3244 2.64597 17.3492L3.20046 17.5285C3.31264 17.5653 3.40092 17.6545 3.43494 17.7676L3.60597 18.3239C3.62988 18.4012 3.73839 18.4021 3.76322 18.3249V18.3239Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5632 18.0042C12.3342 17.7477 11.9756 17.6033 11.6271 17.5435C11.2758 17.4865 10.908 17.492 10.5613 17.5536C9.86063 17.6723 9.22339 17.9628 8.66799 18.3205C8.37925 18.4962 8.13374 18.6994 7.8781 18.9072C7.75489 19.012 7.64362 19.1233 7.52684 19.2336L7.20776 19.5509C6.86109 19.9132 6.51994 20.2424 6.18983 20.5155C5.85787 20.7877 5.54799 20.9936 5.2427 21.138C4.93741 21.2833 4.61098 21.3679 4.18523 21.3817C3.76316 21.3964 3.26385 21.3201 2.7296 21.195C2.19259 21.07 1.62891 20.8916 0.999023 20.738C1.21879 21.3477 1.54983 21.8865 1.97466 22.3794C2.405 22.8631 2.94201 23.3044 3.63167 23.595C4.31121 23.892 5.16546 23.9978 5.96454 23.8378C6.76546 23.6833 7.46799 23.3127 8.03626 22.8842C8.60546 22.4511 9.06707 21.9582 9.45511 21.4451C9.5627 21.3035 9.61879 21.2244 9.69695 21.1132L9.91121 20.795C10.0602 20.5982 10.1963 20.3748 10.3434 20.1798C10.633 19.7716 10.9181 19.3642 11.2491 18.989C11.4156 18.7987 11.5921 18.6166 11.7999 18.4419C11.9038 18.3564 12.0151 18.2727 12.1393 18.1973C12.2652 18.1164 12.3976 18.0529 12.5613 18.0033L12.5632 18.0042Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5632 18.0028C12.3168 17.3803 11.8579 16.8571 11.2428 16.4708C10.6313 16.0883 9.78161 15.8934 8.94023 16.0598C8.5246 16.1398 8.12184 16.2952 7.76782 16.5012C7.41563 16.7062 7.10023 16.9536 6.82805 17.2194C6.69195 17.3527 6.56782 17.4915 6.44552 17.6304L6.12736 18.0368L5.6354 18.6906C5.00828 19.532 4.33333 20.5177 3.22437 20.8092C2.13655 21.0961 1.66483 20.8423 1 20.7375C1.12138 21.0511 1.27218 21.3554 1.4754 21.623C1.67586 21.8961 1.91218 22.1527 2.20552 22.3725C2.35356 22.4782 2.5108 22.5821 2.6846 22.6713C2.85747 22.7568 3.04506 22.8322 3.2446 22.8883C3.64184 22.9968 4.08874 23.0354 4.52092 22.9766C4.95402 22.9187 5.36782 22.7817 5.7292 22.5996C6.09333 22.4194 6.41057 22.1996 6.69379 21.9679C7.25747 21.4998 7.69517 20.983 8.06483 20.4607C8.25058 20.1996 8.41977 19.9338 8.57609 19.6672L8.76 19.3499C8.81609 19.2571 8.8731 19.1642 8.93103 19.0768C9.16368 18.7283 9.39172 18.4488 9.66851 18.2382C9.94161 18.023 10.3214 17.8639 10.8299 17.8272C11.3356 17.7895 11.9195 17.8584 12.5614 18.0028H12.5632Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.82568 23.1713C9.82568 23.6283 10.1963 23.9989 10.6533 23.9989C11.1103 23.9989 11.4809 23.6283 11.4809 23.1713C11.4809 22.7143 11.1103 22.3438 10.6533 22.3438C10.1963 22.3438 9.82568 22.7143 9.82568 23.1713Z"
          fill="white"
        />
      </g>
      <circle cx={21} cy={20} r={7} fill="#469BF5" />
      <g clipPath="url(#clip1_9706_69204)">
        <path
          d="M24.5312 18.5094C24.1796 18.1848 23.6933 17.6891 23.2972 17.3376L23.2738 17.3212C23.2348 17.2899 23.1908 17.2653 23.1437 17.2485C22.1886 17.0704 17.7437 16.2396 17.657 16.2501C17.6327 16.2535 17.6095 16.2623 17.5891 16.2759L17.5668 16.2935C17.5394 16.3213 17.5186 16.3549 17.5059 16.3919L17.5 16.4071V16.4903V16.5032C18.0004 17.8966 19.9761 22.461 20.3652 23.532C20.3886 23.6047 20.4332 23.743 20.5164 23.75H20.5351C20.5796 23.75 20.7695 23.4992 20.7695 23.4992C20.7695 23.4992 24.1632 19.3837 24.5065 18.9454C24.551 18.8914 24.5902 18.8333 24.6237 18.7719C24.6323 18.7239 24.6283 18.6745 24.612 18.6285C24.5958 18.5825 24.5679 18.5415 24.5312 18.5094ZM21.6402 18.9887L23.0886 17.7876L23.9382 18.5704L21.6402 18.9887ZM21.0777 18.9102L18.584 16.8665L22.6187 17.6106L21.0777 18.9102ZM21.3027 19.4458L23.855 19.0344L20.9371 22.55L21.3027 19.4458ZM18.2453 17.0704L20.8691 19.2969L20.4894 22.5524L18.2453 17.0704Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9706_69204">
          <rect
            width={11.5632}
            height={8}
            fill="white"
            transform="translate(1 16)"
          />
        </clipPath>
        <clipPath id="clip1_9706_69204">
          <rect
            width={8}
            height={8}
            fill="white"
            transform="translate(17 16)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgOtherCategory;
