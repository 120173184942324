import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgLogout(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_19665_403207)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.268555 6.51603C0.268555 3.41999 2.77839 0.910156 5.87443 0.910156H11.1186C14.2147 0.910156 16.7245 3.41999 16.7245 6.51604V6.8207C16.7245 7.22453 16.3971 7.5519 15.9933 7.5519C15.5895 7.5519 15.2621 7.22453 15.2621 6.8207V6.51604C15.2621 4.22766 13.407 2.37256 11.1186 2.37256H5.87443C3.58606 2.37256 1.73096 4.22766 1.73096 6.51603V17.4841C1.73096 19.7724 3.58606 21.6275 5.87443 21.6275H11.1186C13.407 21.6275 15.2621 19.7724 15.2621 17.4841V17.1794C15.2621 16.7756 15.5895 16.4482 15.9933 16.4482C16.3971 16.4482 16.7245 16.7756 16.7245 17.1794V17.4841C16.7245 20.5801 14.2147 23.0899 11.1186 23.0899H5.87443C2.77839 23.0899 0.268555 20.5801 0.268555 17.4841V6.51603ZM19.533 8.3803C19.7831 8.06325 20.2429 8.00898 20.56 8.2591C21.2114 8.77302 21.9659 9.38204 22.5599 9.97564C22.8565 10.2721 23.1335 10.5846 23.3408 10.8972C23.5373 11.1936 23.7309 11.5778 23.731 12C23.731 12.4221 23.5373 12.8064 23.3408 13.1028C23.1335 13.4153 22.8565 13.7279 22.5599 14.0244C21.9659 14.618 21.2114 15.227 20.56 15.7409C20.2429 15.991 19.7831 15.9367 19.533 15.6197C19.2829 15.3026 19.3372 14.8429 19.6542 14.5927C20.3082 14.0768 21.0001 13.5157 21.5261 12.99C21.6159 12.9003 21.6984 12.8139 21.7735 12.7312H10.672C10.2682 12.7312 9.94084 12.4038 9.94084 12C9.94084 11.5962 10.2682 11.2688 10.672 11.2688H21.7734C21.6984 11.1861 21.6158 11.0997 21.5261 11.01C21 10.4843 20.3082 9.92316 19.6542 9.40725C19.3372 9.15713 19.2829 8.69735 19.533 8.3803Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_19665_403207">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgLogout;
