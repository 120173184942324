import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgFilter(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.82568 3.82878C2.82568 2.03228 4.28203 0.575928 6.07853 0.575928H17.9208C19.7173 0.575928 21.1737 2.03228 21.1737 3.82878V5.80513C21.1737 6.68795 20.8149 7.53209 20.181 8.1445L15.7568 13.4631L15.7228 13.4949C15.3694 13.8261 15.1689 14.2887 15.1689 14.773V18.8493C15.1689 19.6996 14.836 20.5161 14.2414 21.124L12.2631 23.1462C11.0086 24.4287 8.83041 23.5405 8.83041 21.7465V14.773C8.83041 14.2887 8.6299 13.8261 8.27652 13.4949L8.24251 13.4631L3.81837 8.1445C3.18447 7.53209 2.82568 6.68795 2.82568 5.80513V3.82878ZM6.07853 2.07724C5.11119 2.07724 4.327 2.86143 4.327 3.82878V5.80513C4.327 6.28941 4.52751 6.75208 4.88089 7.08322L4.9149 7.11509L9.33904 12.4336C9.97294 13.046 10.3317 13.8902 10.3317 14.773V21.7465C10.3317 22.195 10.8763 22.417 11.1899 22.0964L13.1682 20.0741C13.4883 19.7468 13.6676 19.3072 13.6676 18.8493V14.773C13.6676 13.8902 14.0264 13.046 14.6603 12.4336L19.0844 7.11509L19.1185 7.08322C19.4718 6.75208 19.6723 6.28941 19.6723 5.80513V3.82878C19.6723 2.86143 18.8882 2.07724 17.9208 2.07724H6.07853Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgFilter;
