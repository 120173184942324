import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgChains(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.7249 10.2885V6.69047C18.7249 6.5286 18.6347 6.38026 18.4908 6.30582L12.199 3.0485C12.0742 2.98383 11.9256 2.98383 11.8008 3.0485L5.50897 6.30582C5.36514 6.38026 5.2749 6.5286 5.2749 6.69047V10.2885C5.2749 10.4504 5.36514 10.5988 5.50897 10.6733L8.07348 12.0002L5.50897 13.3267C5.36514 13.4012 5.2749 13.5496 5.2749 13.7115L5.27528 17.3102C5.27528 17.4721 5.36552 17.6205 5.50934 17.6948L11.8008 20.9515C11.8632 20.9838 11.9315 21 11.9999 21C12.0683 21 12.1366 20.9838 12.199 20.9515L18.4905 17.6948C18.6343 17.6205 18.7245 17.4721 18.7245 17.3102L18.7249 13.7115C18.7249 13.5496 18.6347 13.4012 18.4908 13.3267L15.9263 12.0002L18.4908 10.6733C18.6347 10.5988 18.7249 10.4504 18.7249 10.2885ZM11.9999 3.92092L17.3489 6.69011L11.9999 9.46104L6.65085 6.69011L11.9999 3.92092ZM17.8582 17.0466L11.9999 20.0791L6.14158 17.0466L6.14127 14.4237L11.8006 17.3553C11.8632 17.3876 11.9315 17.4038 11.9999 17.4038C12.0683 17.4038 12.1366 17.3876 12.1992 17.3553L17.8585 14.4237L17.8582 17.0466ZM17.3495 13.7117L11.9999 16.4828L6.65034 13.7117L9.01624 12.488L11.8008 13.9287C11.8632 13.9609 11.9317 13.9771 11.9999 13.9771C12.0681 13.9771 12.1366 13.9609 12.199 13.9287L14.9836 12.488L17.3495 13.7117ZM17.8586 10.0249L11.9999 13.0563L6.14121 10.0249V7.40188L11.8006 10.3336C11.8632 10.3659 11.9315 10.3821 11.9999 10.3821C12.0683 10.3821 12.1366 10.3659 12.1992 10.3336L17.8586 7.40188V10.0249Z"
        fill="#727272"
      />
    </svg>
  );
}
export default SvgChains;
