import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgUtxoCategory(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 29 28" xmlns="http://www.w3.org/2000/svg">
      <circle cx={14.75} cy={8} r={7} fill="#469BF5" />
      <path
        d="M17.6511 7.26507C17.7689 6.47699 17.1689 6.05337 16.3485 5.77078L16.6147 4.70326L15.9648 4.54134L15.7057 5.58075C15.5349 5.53814 15.3595 5.49799 15.1851 5.45819L15.4461 4.41192L14.7966 4.25L14.5303 5.31717C14.389 5.28498 14.2501 5.25317 14.1154 5.21965L14.1162 5.21629L13.22 4.99251L13.0472 5.68657C13.0472 5.68657 13.5293 5.79709 13.5191 5.80389C13.7823 5.86957 13.8299 6.04376 13.822 6.18183L13.5188 7.39799C13.5369 7.40259 13.5604 7.40925 13.5863 7.41967C13.5647 7.41428 13.5416 7.40841 13.5177 7.40268L13.0927 9.10633C13.0605 9.18628 12.9789 9.30626 12.7949 9.2607C12.8014 9.27014 12.3226 9.14283 12.3226 9.14283L12 9.88668L12.8456 10.0975C13.003 10.1369 13.1571 10.1782 13.309 10.217L13.04 11.2968L13.6891 11.4587L13.9554 10.3904C14.1327 10.4386 14.3048 10.483 14.4733 10.5248L14.2079 11.5881L14.8577 11.75L15.1266 10.6723C16.2347 10.882 17.0679 10.7974 17.4186 9.79515C17.7012 8.98821 17.4046 8.52276 16.8216 8.21923C17.2462 8.12131 17.5658 7.84196 17.6511 7.26507ZM16.1664 9.34702C15.9655 10.154 14.6069 9.71775 14.1664 9.60837L14.5232 8.17787C14.9637 8.28784 16.3762 8.50545 16.1664 9.34702ZM16.3673 7.25338C16.1841 7.98738 15.0533 7.61447 14.6865 7.52303L15.01 6.22566C15.3768 6.3171 16.5581 6.48776 16.3673 7.25338Z"
        fill="white"
      />
      <circle cx={7.75} cy={20} r={7} fill="#469BF5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.2203 22.2684L7.77769 20.3888L8.64617 20.1426L8.82765 19.5204L7.97212 19.7796L8.8795 16.6945V16.6453C8.87883 16.6255 8.87427 16.6061 8.86609 16.5881C8.85791 16.5701 8.84626 16.5539 8.83182 16.5404C8.81737 16.527 8.80041 16.5165 8.7819 16.5095C8.76339 16.5026 8.7437 16.4994 8.72395 16.5001H7.57029C7.52661 16.4987 7.48374 16.5121 7.4485 16.5379C7.41327 16.5637 7.38769 16.6006 7.37585 16.6427L6.29996 20.2981L5.44444 20.5574L5.25 21.1536L6.10552 20.8944L5.34074 23.4998H9.90353C9.94731 23.5016 9.9904 23.4885 10.0257 23.4626C10.0611 23.4367 10.0865 23.3995 10.098 23.3572L10.3572 22.4628V22.4136C10.3566 22.3938 10.352 22.3744 10.3438 22.3564C10.3356 22.3384 10.324 22.3222 10.3095 22.3087C10.2951 22.2953 10.2781 22.2848 10.2596 22.2778C10.2411 22.2709 10.2214 22.2677 10.2017 22.2684H7.2203Z"
        fill="white"
      />
      <circle cx={21.75} cy={20} r={7} fill="#469BF5" />
      <g clipPath="url(#clip0_9706_95224)">
        <path
          d="M24.0422 18.076C23.8054 17.3151 23.0809 17.1948 22.2192 17.2974L21.9943 16.2204L21.3388 16.3574L21.5578 17.406C21.3854 17.4419 21.21 17.4821 21.0357 17.5222L20.8154 16.4666L20.1602 16.6034L20.3848 17.6801C20.2435 17.7126 20.1047 17.7444 19.9688 17.7729L19.968 17.7695L19.0638 17.958L19.2102 18.6581C19.2102 18.6581 19.6923 18.5478 19.6861 18.5583C19.9516 18.503 20.0703 18.6391 20.1233 18.7669L20.3795 19.9938C20.3978 19.99 20.4219 19.9858 20.4497 19.9839C20.4279 19.9885 20.4046 19.9932 20.3805 19.9985L20.7392 21.7173C20.7451 21.8033 20.7238 21.9468 20.5383 21.9859C20.5483 21.9915 20.0618 22.0853 20.0618 22.0853L20.095 22.8954L20.9481 22.7172C21.1069 22.6843 21.2637 22.6543 21.4172 22.6232L21.645 23.7124L22.2998 23.5758L22.0747 22.498C22.2553 22.4642 22.4296 22.4293 22.5995 22.3937L22.8232 23.4665L23.4788 23.3295L23.2519 22.2422C24.3408 21.9488 25.0542 21.5101 24.9339 20.4551C24.8372 19.6056 24.3675 19.3156 23.7106 19.296C24.0503 19.0231 24.2164 18.6325 24.0422 18.076ZM23.6114 20.5965C23.7817 21.4105 22.3686 21.6089 21.9244 21.7021L21.6232 20.2589C22.0676 20.1662 23.4341 19.7475 23.6114 20.5965ZM22.8813 18.624C23.0357 19.3646 21.8553 19.5209 21.4852 19.5982L21.212 18.2894C21.5821 18.2121 22.7199 17.8517 22.8813 18.624Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9706_95224">
          <rect
            width={8}
            height={8}
            fill="white"
            transform="translate(16.408 18.1406) rotate(-25.7942)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgUtxoCategory;
