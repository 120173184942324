import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgSupport(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        d="M7.77732 12.5006C7.82378 12.5797 7.90809 12.6296 8.001 12.6296C8.09392 12.6296 8.17823 12.5797 8.22468 12.5006L8.9353 11.2686C10.7368 10.8402 12.0324 9.20905 12.0324 7.34562C12.0324 5.12259 10.224 3.3125 7.99928 3.3125C5.77453 3.3125 3.96788 5.12259 3.96788 7.34562C3.96788 9.20905 5.2635 10.8419 7.06499 11.2686L7.77732 12.5006ZM4.48407 7.34562C4.48407 5.40649 6.06187 3.82868 8.001 3.82868C9.94014 3.82868 11.5179 5.40649 11.5179 7.34562C11.5179 9.00601 10.3393 10.4531 8.71678 10.7886C8.64451 10.8041 8.58257 10.8488 8.54472 10.9125L8.001 11.8554L7.45729 10.9125C7.42116 10.8488 7.35749 10.8041 7.28523 10.7886C5.66269 10.4548 4.48407 9.00601 4.48407 7.34562Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M9.81797 8.87615C9.81797 8.73334 9.70268 8.61806 9.55987 8.61806H6.44212C6.29931 8.61806 6.18403 8.73334 6.18403 8.87615C6.18403 9.01896 6.29931 9.13424 6.44212 9.13424H9.55815C9.70096 9.13424 9.81797 9.01896 9.81797 8.87615Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M6.44212 5.91896H9.55815C9.70096 5.91896 9.81625 5.80368 9.81625 5.66087C9.81625 5.51806 9.70096 5.40278 9.55815 5.40278H6.44212C6.29931 5.40278 6.18403 5.51806 6.18403 5.66087C6.18403 5.80368 6.30103 5.91896 6.44212 5.91896Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M10.1706 7.26504C10.1706 7.12223 10.0553 7.00694 9.91246 7.00694H6.08926C5.94644 7.00694 5.83116 7.12223 5.83116 7.26504C5.83116 7.40785 5.94644 7.52313 6.08926 7.52313H9.91074C10.0553 7.52313 10.1706 7.40785 10.1706 7.26504Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M15.1001 5.72137H15.0278C14.7956 5.72137 14.5856 5.81256 14.4256 5.95709C13.6772 3.10947 11.0808 1 8.00086 1C4.92096 1 2.32455 3.10947 1.5778 5.95709C1.41779 5.81256 1.20787 5.72137 0.975589 5.72137H0.901602C0.404344 5.72137 0 6.12571 0 6.62297V8.65674C0 9.15399 0.404344 9.55834 0.901602 9.55834H0.973868C1.47113 9.55834 1.87547 9.15399 1.87547 8.65674V7.63985C1.87547 4.26229 4.62329 1.51618 7.99914 1.51618C11.375 1.51618 14.1228 4.26401 14.1228 7.63985C14.1228 10.3946 12.3041 12.7862 9.67502 13.5312C9.52704 13.3093 9.27411 13.1647 8.98849 13.1647H7.88041C7.42445 13.1647 7.05452 13.5347 7.05452 13.9906V14.0543C7.05452 14.5103 7.42445 14.8802 7.88041 14.8802H8.99021C9.44617 14.8802 9.81611 14.5103 9.81611 14.0543V14.0285C12.1148 13.3781 13.8423 11.568 14.4256 9.32433C14.5856 9.46886 14.7956 9.55834 15.0261 9.55834H15.0984C15.5956 9.55834 16 9.15399 16 8.65674V6.62469C16.0017 6.12743 15.5974 5.72137 15.1001 5.72137ZM1.36101 8.65674C1.36101 8.87009 1.18722 9.04215 0.975589 9.04215H0.901602C0.688246 9.04215 0.516184 8.86837 0.516184 8.65674V6.62469C0.516184 6.41133 0.689966 6.23927 0.901602 6.23927H0.973868C1.18722 6.23927 1.35929 6.41305 1.35929 6.62469V8.65674H1.36101ZM9.30164 14.0543C9.30164 14.2246 9.16227 14.364 8.99193 14.364H7.88041C7.71007 14.364 7.5707 14.2246 7.5707 14.0543V13.9906C7.5707 13.8203 7.71007 13.6809 7.88041 13.6809H8.99021C9.11582 13.6809 9.22594 13.7566 9.27411 13.8668C9.27583 13.8788 9.27583 13.8891 9.27927 13.9012C9.28272 13.9149 9.29132 13.927 9.29648 13.939C9.29992 13.9562 9.30164 13.9734 9.30164 13.9906V14.0543ZM15.4855 8.65674C15.4855 8.87009 15.3117 9.04215 15.1001 9.04215H15.0278C14.8145 9.04215 14.6424 8.86837 14.6424 8.65674V6.62469C14.6424 6.41133 14.8162 6.23927 15.0278 6.23927H15.1001C15.3135 6.23927 15.4855 6.41305 15.4855 6.62469V8.65674Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgSupport;
