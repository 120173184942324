import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgTransaction(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.72071 5.68769C1.71769 2.54635 4.26488 -0.00214142 7.40636 1.35055e-06C10.77 0.00229565 14.1336 0.0022956 17.4972 1.35055e-06C20.6387 -0.00214148 23.1856 2.5459 23.1849 5.68684C23.1845 7.86988 23.1856 9.9259 23.1869 12.0692C23.188 14.0266 23.1892 16.0568 23.1892 18.323C23.1892 18.4865 23.1242 18.6433 23.0086 18.7589L17.9483 23.8194C17.8327 23.935 17.6758 24 17.5123 24C15.9522 24 14.295 23.9993 12.6072 23.9986C10.8818 23.9979 9.1243 23.9971 7.40581 23.9971C4.26467 23.9971 1.71769 21.4485 1.72071 18.3071C1.7249 13.9398 1.7249 10.055 1.72071 5.68769ZM7.40552 1.2332C4.94605 1.23152 2.95155 3.22689 2.95391 5.68651C2.9581 10.0546 2.9581 13.9402 2.95391 18.3083C2.95155 20.7679 4.94602 22.7639 7.40583 22.7639C9.13703 22.7639 10.8892 22.7647 12.6094 22.7654C14.0722 22.766 15.5119 22.7666 16.8957 22.7668V19.5881C16.8957 18.5488 17.7382 17.7064 18.7774 17.7064H21.956C21.9558 15.6878 21.9547 13.8496 21.9537 12.0711C21.9524 9.92644 21.9513 7.8687 21.9517 5.68659C21.9522 3.22658 19.9575 1.23152 17.498 1.2332C14.1339 1.2355 10.7697 1.2355 7.40552 1.2332ZM21.084 18.9396H18.7774C18.4192 18.9396 18.1289 19.2299 18.1289 19.5881V21.8948L21.084 18.9396ZM6.7748 5.67184C6.7748 5.3313 7.05087 5.05524 7.3914 5.05524H17.5122C17.8527 5.05524 18.1288 5.3313 18.1288 5.67184C18.1288 6.01238 17.8527 6.28844 17.5122 6.28844H7.3914C7.05087 6.28844 6.7748 6.01238 6.7748 5.67184ZM6.7748 9.46718C6.7748 9.12664 7.05087 8.85058 7.3914 8.85058H17.5122C17.8527 8.85058 18.1288 9.12664 18.1288 9.46718C18.1288 9.80772 17.8527 10.0838 17.5122 10.0838H7.3914C7.05087 10.0838 6.7748 9.80772 6.7748 9.46718ZM6.7748 13.2625C6.7748 12.922 7.05087 12.6459 7.3914 12.6459H12.4518C12.7924 12.6459 13.0684 12.922 13.0684 13.2625C13.0684 13.603 12.7924 13.8791 12.4518 13.8791H7.3914C7.05087 13.8791 6.7748 13.603 6.7748 13.2625Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgTransaction;
