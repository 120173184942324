import type { SvgIconPropsWithChildren } from '../components/SvgIcon';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon';

function SvgTarget(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g id="Custom token">
        <path
          id="Icon (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0004 0.174805C12.456 0.174805 12.8254 0.544173 12.8254 0.999811V2.40968C17.483 2.80507 21.1952 6.51725 21.5906 11.1749H23.0005C23.4561 11.1749 23.8255 11.5443 23.8255 11.9999C23.8255 12.4555 23.4561 12.8249 23.0005 12.8249H21.5906C21.1952 17.4825 17.483 21.1947 12.8254 21.5901V23C12.8254 23.4556 12.456 23.825 12.0004 23.825C11.5447 23.825 11.1754 23.4556 11.1754 23V21.5901C6.51773 21.1947 2.80556 17.4825 2.41017 12.8249H1.0003C0.544661 12.8249 0.175293 12.4555 0.175293 11.9999C0.175293 11.5443 0.544661 11.1749 1.0003 11.1749H2.41017C2.80556 6.51725 6.51773 2.80507 11.1754 2.40968V0.999811C11.1754 0.544173 11.5447 0.174805 12.0004 0.174805ZM11.1754 4.06699C7.42972 4.45195 4.45243 7.42923 4.06748 11.1749H5.40033C5.85597 11.1749 6.22534 11.5443 6.22534 11.9999C6.22534 12.4555 5.85597 12.8249 5.40033 12.8249H4.06748C4.45243 16.5706 7.42972 19.5478 11.1754 19.9328V18.5999C11.1754 18.1443 11.5447 17.7749 12.0004 17.7749C12.456 17.7749 12.8254 18.1443 12.8254 18.5999V19.9328C16.571 19.5478 19.5483 16.5706 19.9333 12.8249H18.6004C18.1448 12.8249 17.7754 12.4555 17.7754 11.9999C17.7754 11.5443 18.1448 11.1749 18.6004 11.1749H19.9333C19.5483 7.42923 16.571 4.45195 12.8254 4.06699V5.39984C12.8254 5.85548 12.456 6.22485 12.0004 6.22485C11.5447 6.22485 11.1754 5.85548 11.1754 5.39984V4.06699ZM12.0004 8.97487C12.456 8.97487 12.8254 9.34424 12.8254 9.79988V11.1749H14.2004C14.656 11.1749 15.0254 11.5443 15.0254 11.9999C15.0254 12.4555 14.656 12.8249 14.2004 12.8249H12.8254V14.1999C12.8254 14.6555 12.456 15.0249 12.0004 15.0249C11.5447 15.0249 11.1754 14.6555 11.1754 14.1999V12.8249H9.80037C9.34473 12.8249 8.97536 12.4555 8.97536 11.9999C8.97536 11.5443 9.34473 11.1749 9.80037 11.1749H11.1754V9.79988C11.1754 9.34424 11.5447 8.97487 12.0004 8.97487Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
export default SvgTarget;
